import React, { useState, useCallback, useEffect } from "react";
import { models } from "powerbi-client";
import "powerbi-report-authoring";
import "./PowerBI.css";
import { getPowerBIEmbedToken } from "../../store/services/powerBI.service";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../routes/routes.constatns";
import { useSelector } from "react-redux";
import { useGetPowerBiAuthToken } from "../../hooks/useAppToken";
import { Button } from "../Button/Button";

export function PowerBI() {
  const engagement = useSelector((state) => state.engagement.engagement);
  const project = useSelector((state) => state.project.project);
  const { state } = useLocation();
  const user = useSelector((state) => state.user.user);
  const accessToken = useGetPowerBiAuthToken();
  const selectedRegion = useSelector(
    (state) => state.engagement.selectedRegion
  );
  const history = useNavigate();

  const [reportConfig, setReportConfig] = useState({
    type: "report",
    embedUrl: undefined,
    id: state?.reportId,
    tokenType: models.TokenType.Aad,
    permissions: models.Permissions.All,
    viewMode: models.ViewMode.View,
    accessToken: undefined,
    settings: {
      panes: {
        filters: {
          expanded: false,
          visible: false,
        },
        pageNavigation: {
          visible: false,
        },
      },
    },
  });

  const getReport = useCallback(
    async (token) => {
      const response = await getPowerBIEmbedToken({
        request: {
          username: user.username,
          datasetId: "",
          accessToken: token,
          baseUrl: selectedRegion.apiUrl,
        },
      });

      const reportConfig = {
        Id: state?.reportId,
        EmbedUrl: `https://app.powerbi.com/reportEmbed?reportId=${state?.reportId}&autoAuth=true&filter=vw_EngagementMaster/EngagementCode eq '${engagement.engagementCode}'`,
        Type: "PowerBIReport",
        EmbedToken: {
          Token: response.embedToken,
          TokenId: response.embedTokenId,
          Expiration: response.expiration,
        },
        MinutesToExpiration: 1,
        DefaultPage: null,
        MobileDefaultPage: null,
      };

      setReportConfig({
        ...reportConfig,
        embedUrl: reportConfig.EmbedUrl,
        accessToken: reportConfig.EmbedToken.Token,
      });
    },
    [state?.reportId]
  );

  useEffect(() => {
    if (!accessToken) return;

    getReport(accessToken);
  }, [getReport, accessToken]);

  const goBack = () => {
    history(-1);
  };

  function getEmbedUrl() {
    // let projectQuery = project?.projectId
    //   ? `vw_decarb_project/ProjectID eq '${project?.projectId}'`
    //   : `vw_EngagementMaster/EngagementCode eq '${engagement.engagementCode}'`;

    // if (state?.scenarioId) {
    //   projectQuery = `vw_Scenario/ScenarioId eq '${state?.scenarioId}'`;
    // }

    let projectQuery = `vw_EngagementMaster/EngagementCode eq '${engagement.engagementCode}'`;

    return `https://app.powerbi.com/reportEmbed?reportId=${state?.reportId}&autoAuth=true&filter=${projectQuery}`;
  }

  return (
    <>
      <iframe
        title="Decarb-GHG Emissions Insights-M1"
        width="1140"
        height="500.25"
        src={getEmbedUrl()}
        frameBorder="0"
        allowFullScreen={true}
        className="report-container"
      ></iframe>
      <div className="powerbi-button">
        <Button
          variant="primary-alt"
          onClick={() => {
            goBack();
          }}
        >
          {"Close"}
        </Button>
      </div>
      {/*     <PowerBIEmbed
        embedConfig={reportConfig}
        cssClassName={"report-container"}
      />*/}
    </>
  );
}
