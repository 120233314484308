import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserPhoto } from "../services/user.service";
import { Buffer } from "buffer";

const initialState = {
  user: {},
  photo: "",
  isAuthenticated: false,
  jwtToken: "",
};

export const getPhoto = createAsyncThunk("me/photo", async (request) => {
  const apiResponse = await getUserPhoto({ request });

  let response = `data:image/jpeg;base64,${Buffer.from(
    apiResponse.data,
    "binary"
  ).toString("base64")}`;

  return response;
});

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.isAuthenticated = true;
    },
    setJWtToken: (state, action) => {
      state.jwtToken = action.payload;
    },
    logoutUser: (state) => {
      state.isAuthenticated = initialState.isAuthenticated;
      sessionStorage.clear();
      state.user = initialState.user;
    },
  },
  extraReducers(builder) {
    builder.addCase(getPhoto.fulfilled, (state, action) => {
      state.photo = action.payload;
    });
  },
});

export const { setUser, logoutUser, setJWtToken } = userSlice.actions;

export default userSlice.reducer;
