import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Simulate } from "../../components/Simulate/components/createNewScenario/components/Simulate";
import { SaveScenario } from "../../components/Simulate/components/createNewScenario/components/SaveScenario";
import {
  getM4GetProjectsScenarios,
  getM4NavBarProjects,
} from "../services/project.service";
import {
  deleteScenarioDetail,
  getScenarioDetails,
} from "../services/simulate.service";
import { SIMULATE_URL } from "../services/endpoints/simulate";

const initialState = {
  scope: undefined,
  isEditScenario: false,
  trajectoryGraphData: [],
  trajectoryCardData: [],
  simulateSteps: [
    {
      stepComponent: <SaveScenario />,
    },
    {
      stepComponent: <Simulate />,
    },
  ],
  scenario: [],
  currentSimulateStep: 0,
  currentSimulateStepIndex: 0,
  projectScenariosData: [],
  projectScenariosDataLoading: false,
  navBarProjectsData: [],
  navBarProjectsDataLoading: false,
  error: null,
};

export const getProjectScenarios = createAsyncThunk(
  "Project/GetProjectsScenario",
  async (request) => {
    const apiResponse = await getM4GetProjectsScenarios({ request });

    return apiResponse;
  }
);

export const getNavBarProjects = createAsyncThunk(
  "Project/GetNavBarProjects",
  async (request) => {
    const apiResponse = await getM4NavBarProjects({ request });
    let response = [];

    if (apiResponse && apiResponse.length > 0) {
      const groupedData = apiResponse.reduce((group, product) => {
        const { projectTypeID } = product;
        group[projectTypeID] = group[projectTypeID] ?? [];
        group[projectTypeID].children = group[projectTypeID]?.children ?? [];
        group[projectTypeID].children.push({
          key: product.projectID,
          title: product.projectName,
          checked: product.isSelected === 1,
          children: [],
        });
        group[projectTypeID].count = group[projectTypeID].children.length;
        group[projectTypeID].key = product.projectTypeID;
        group[projectTypeID].title = product.projectTypename;

        return group;
      }, {});

      const groupedProjects = Object.values(groupedData);

      const allProjects = {
        key: "ALL",
        title: "All projects",
        count: apiResponse.length,
        children: groupedProjects,
      };

      response = [allProjects];
    }

    return response;
  }
);

export const deleteScenario = createAsyncThunk(
  SIMULATE_URL.DeleteScenarioDetail,
  async (request) => {
    await deleteScenarioDetail({ request });

    return request.scenarioId;
  }
);

export const getScenarioDetail = createAsyncThunk(
  SIMULATE_URL.GetScenarioDetails,
  async (request) => {
    const apiResponse = await getScenarioDetails({ request });

    return {
      scenarioID: apiResponse.scenarioID,
      scenarioName: apiResponse.scenarioName,
      scenarioDescrption: apiResponse.scenario_Desc,
    };
  }
);

const simulateSlice = createSlice({
  name: "simulate",
  initialState,
  reducers: {
    previousSimulateStep(state) {
      state.currentSimulateStep = state.currentSimulateStep - 1;
    },
    nextSimulateStep(state) {
      state.currentSimulateStep = state.currentSimulateStep + 1;
    },
    setSimulateStepIndex(state, action) {
      const { index } = action.payload;

      state.currentSimulateStepIndex = index;
    },
    setIsNextTabButtonDisabled(state, action) {
      const { isDisabled } = action.payload;

      state.isNextTabButtonDisabled = isDisabled;
    },
    setScope(state, action) {
      const { scope } = action.payload;

      state.scope = scope;
    },
    setGraphData(state, action) {
      const { graphData } = action.payload;
      state.trajectoryGraphData = graphData;
    },
    setCardData(state, action) {
      const { cardData } = action.payload;
      state.trajectoryCardData = cardData;
    },
    setScenarioName(state, action) {
      const { name } = action.payload;
      state.scenario = { ...state.scenario, scenarioName: name };
    },
    setScenarioDesc(state, action) {
      const { descrp } = action.payload;
      state.scenario = { ...state.scenario, scenarioDescrption: descrp };
    },
    setScenarioDetails(state, action) {
      const { scenarioID } = action.payload;
      state.scenario = { ...state.scenario, scenarioID: scenarioID };
    },
    setIsScenarioValidated(state, action) {
      const { scenarioValidate } = action.payload;
      state.scenario = {
        ...state.scenario,
        scenarioValidate: scenarioValidate,
      };
    },
    isEditScenario(state, action) {
      const { editScenario } = action.payload;
      state.isEditScenario = editScenario;
    },
    setGenerateGraphResponse(state, action) {
      const { generateGraphResponse } = action.payload;
      state.scenario = {
        ...state.scenario,
        generateGraphResponse: generateGraphResponse,
      };
    },
    dropSimulateState(state) {
      state.scope = initialState.scope;
      state.isEditScenario = initialState.isEditScenario;
      state.simulateSteps = initialState.simulateSteps;
      state.currentSimulateStep = initialState.currentSimulateStep;
      state.currentSimulateStepIndex = initialState.currentSimulateStepIndex;
      state.projectScenariosData = initialState.projectScenariosData;
      state.projectScenariosDataLoading =
        initialState.projectScenariosDataLoading;
      state.navBarProjectsData = initialState.navBarProjectsData;
      state.navBarProjectsDataLoading = initialState.navBarProjectsDataLoading;
      state.error = initialState.error;
      // state.scenario.generateGraphResponse = null;
      // state.scenario.scenarioValidate = "Y";
      // state.scenario.scenarioName = "";
      // state.scenario.scenarioDescrption = "";
      state.scenario = initialState.scenario;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getProjectScenarios.pending, (state) => {
        state.projectScenariosDataLoading = true;
      })
      .addCase(getProjectScenarios.fulfilled, (state, action) => {
        state.projectScenariosDataLoading = false;
        state.projectScenariosData = action.payload;
      })
      .addCase(getProjectScenarios.rejected, (state, action) => {
        state.projectScenariosDataLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(getNavBarProjects.pending, (state) => {
        state.navBarProjectsDataLoading = true;
      })
      .addCase(getNavBarProjects.fulfilled, (state, action) => {
        state.navBarProjectsDataLoading = false;
        state.navBarProjectsData = action.payload;
      })
      .addCase(getNavBarProjects.rejected, (state, action) => {
        state.navBarProjectsDataLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(deleteScenario.pending, (state) => {
        state.projectScenariosDataLoading = true;
      })
      .addCase(deleteScenario.fulfilled, (state, action) => {
        state.projectScenariosDataLoading = false;
        state.projectScenariosData = state.projectScenariosData.filter(
          (x) => x.scenarioID !== action.payload
        );
      })
      .addCase(deleteScenario.rejected, (state, action) => {
        state.projectScenariosDataLoading = false;
        state.error = action.error.message;
      });
    builder
      // .addCase(getScenarioDetail.pending, (state) => {
      //   state.projectScenariosDataLoading = true;
      // })
      .addCase(getScenarioDetail.fulfilled, (state, action) => {
        state.scenario = { ...state.scenario, ...action.payload };
        //state.projectScenariosDataLoading = false;
        state.projectScenariosData = state.projectScenariosData.filter(
          (x) => x.scenarioID !== action.payload
        );
      })
      .addCase(getScenarioDetail.rejected, (state, action) => {
        //state.projectScenariosDataLoading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  setScope,
  isEditScenario,
  setGraphData,
  setCardData,
  setScenarioName,
  setScenarioDesc,
  previousSimulateStep,
  nextSimulateStep,
  setSimulateStepIndex,
  setScenarioDetails,
  setIsScenarioValidated,
  setGenerateGraphResponse,
  dropSimulateState,
  setIsNextTabButtonDisabled,
} = simulateSlice.actions;

export default simulateSlice.reducer;
