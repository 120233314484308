import { FORECAST_URL } from "./endpoints/forecast";
import { get, post } from "./base.service";

async function getForecastRecords({ request }) {
  // return await get(
  //   `${FORECAST_URL.GetForecastRecords}?EngagementID=${request.engagementId}`
  // );
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "GET",
    ControllerName: "Forecast",
    ActionName: FORECAST_URL.GetForecastRecords,
    QueryString: "EngagementID=" + request.engagementId,
    Data: "",
  });
}

async function saveForecastSpecification({ payload }) {
  // return await post(`${FORECAST_URL.SaveForecastSpecification}`, payload);
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "POST",
    ControllerName: "Forecast",
    ActionName: FORECAST_URL.SaveForecastSpecification,
    QueryString: "",
    Data: payload,
  });
}

async function allSaveForecastSpecification({ allpayload }) {
  // return await post(`${FORECAST_URL.AllSaveForecastspecification}`, allpayload);
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "POST",
    ControllerName: "Forecast",
    ActionName: FORECAST_URL.AllSaveForecastspecification,
    QueryString: "",
    Data: allpayload,
  });
}

async function allSaveTargetSpecification({ allpayload }) {
  // return await post(`${FORECAST_URL.AllSaveTargetspecification}`, allpayload);
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "POST",
    ControllerName: "Forecast",
    ActionName: FORECAST_URL.AllSaveTargetspecification,
    QueryString: "",
    Data: allpayload,
  });
}

async function editForecastSpecification({ request }) {
  // return await post(`${FORECAST_URL.UpdateForecastSpecification}`, request);
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "POST",
    ControllerName: "Forecast",
    ActionName: FORECAST_URL.UpdateForecastSpecification,
    QueryString: "",
    Data: request,
  });
}

async function getTargetRecords({ request }) {
  // return await get(
  //   `${FORECAST_URL.GetTargetRecords}?EngagementID=${request.engagementId}`
  // );
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "GET",
    ControllerName: "Forecast",
    ActionName: FORECAST_URL.GetTargetRecords,
    QueryString: "EngagementID=" + request.engagementId,
    Data: "",
  });
}

async function saveTargetSpecification({ payload }) {
  // return await post(`${FORECAST_URL.SaveTargetSpecification}`, payload);
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "POST",
    ControllerName: "Forecast",
    ActionName: FORECAST_URL.SaveTargetSpecification,
    QueryString: "",
    Data: payload,
  });
}

async function updateTargetSpecification({ payload }) {
  // return await post(`${FORECAST_URL.UpdateTargetSpecification}`, payload);
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "POST",
    ControllerName: "Forecast",
    ActionName: FORECAST_URL.UpdateTargetSpecification,
    QueryString: "",
    Data: payload,
  });
}

async function updateForecastSpecification({ payload }) {
  // return await post(`${FORECAST_URL.UpdateForecastSpecification}`, payload);
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "POST",
    ControllerName: "Forecast",
    ActionName: FORECAST_URL.UpdateForecastSpecification,
    QueryString: "",
    Data: payload,
  });
}

async function getForecastTargetByGroupId({ request }) {
  // return await get(
  //   `${FORECAST_URL.GetTargetSpecificationByBatchGroupId}?EngagementID=${request.engagementId}&BatchGroupID=${request.batchGroupId}`
  // );
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "GET",
    ControllerName: "Forecast",
    ActionName: FORECAST_URL.GetTargetSpecificationByBatchGroupId,
    QueryString:
      "engagementId=" +
      request.engagementId +
      `&` +
      "BatchGroupID=" +
      request.batchGroupId,
    Data: "",
  });
}

async function getForecastByGroupId({ request }) {
  // return await get(
  //   `${FORECAST_URL.GetForecastSpecificationByBatchGroupId}?EngagementID=${request.engagementId}&BatchGroupID=${request.batchGroupId}`
  // );
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "GET",
    ControllerName: "Forecast",
    ActionName: FORECAST_URL.GetForecastSpecificationByBatchGroupId,
    QueryString:
      "engagementId=" +
      request.engagementId +
      `&` +
      "BatchGroupID=" +
      request.batchGroupId,
    Data: "",
  });
}

async function updateBatchIdForTargetSpecification({ payload }) {
  // return await post(
  //   `${FORECAST_URL.UpdateBatchIdForTargetSpecifcationAndCallSP}?engagementId=${payload.engagementId}`
  // );
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "POST",
    ControllerName: "Forecast",
    ActionName: FORECAST_URL.UpdateBatchIdForTargetSpecifcationAndCallSP,
    QueryString: "engagementId=" + payload.engagementId,
    Data: "",
  });
}

async function updateBatchIdForForecastSpecification({ payload }) {
  // return await post(
  //   `${FORECAST_URL.UpdateBatchIdForForecastSpecifcationAndCallSP}?engagementId=${payload.engagementId}`
  // );
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "POST",
    ControllerName: "Forecast",
    ActionName: FORECAST_URL.UpdateBatchIdForForecastSpecifcationAndCallSP,
    QueryString: "engagementId=" + payload.engagementId,
    Data: "",
  });
}

async function deleteForecast({ request }) {
  // return await post(
  //   `${FORECAST_URL.DeleteForecast}?engagementId=${request.engagementId}&batchgroupId=${request.batchGroupId}`
  // );
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "POST",
    ControllerName: "Forecast",
    ActionName: FORECAST_URL.DeleteForecast,
    QueryString:
      "engagementId=" +
      request.engagementId +
      `&` +
      "batchgroupId=" +
      request.batchGroupId,
    Data: "",
  });
}

async function deleteTarget({ request }) {
  // return await post(
  //   `${FORECAST_URL.DeleteTarget}?engagementId=${request.engagementId}&batchgroupId=${request.batchGroupId}`
  // );
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "POST",
    ControllerName: "Forecast",
    ActionName: FORECAST_URL.DeleteTarget,
    QueryString:
      "engagementId=" +
      request.engagementId +
      `&` +
      "batchgroupId=" +
      request.batchGroupId,
    Data: "",
  });
}

async function getForecastSpecificationProcessingStatus({ request }) {
  // return await get(
  //   `${FORECAST_URL.GetForecastSpecificationProcessingStatus}?engagementId=${request.engagementId}`
  // );
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "GET",
    ControllerName: "Forecast",
    ActionName: FORECAST_URL.GetForecastSpecificationProcessingStatus,
    QueryString: "engagementId=" + request.engagementId,
    Data: "",
  });
}

async function getTargetSpecificationProcessingStatus({ request }) {
  // return await get(
  //   `${FORECAST_URL.GetTargetSpecificationProcessingStatus}?engagementId=${request.engagementId}`
  // );
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "GET",
    ControllerName: "Forecast",
    ActionName: FORECAST_URL.GetTargetSpecificationProcessingStatus,
    QueryString: "engagementId=" + request.engagementId,
    Data: "",
  });
}

async function applyForecast({ request }) {
  // return await post(
  //   `${FORECAST_URL.ApplyForecast}?engagementId=${request.engagementId}`
  // );
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "POST",
    ControllerName: "Forecast",
    ActionName: FORECAST_URL.ApplyForecast,
    QueryString: "engagementId=" + request.engagementId,
    Data: "",
  });
}

async function applyTarget({ request }) {
  // return await post(
  //   `${FORECAST_URL.ApplyTarget}?engagementId=${request.engagementId}`
  // );
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "POST",
    ControllerName: "Forecast",
    ActionName: FORECAST_URL.ApplyTarget,
    QueryString: "engagementId=" + request.engagementId,
    Data: "",
  });
}

async function setShowTarget({ request }) {
  // return await post(
  //   `${FORECAST_URL.SetShowTarget}?engagementId=${request.engagementId}&status=${request.status}`
  // );
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "POST",
    ControllerName: "Forecast",
    ActionName: FORECAST_URL.SetShowTarget,
    QueryString:
      "engagementId=" + request.engagementId + `&` + "status=" + request.status,
    Data: "",
  });
}

async function setShowForecast({ request }) {
  // return await post(
  //   `${FORECAST_URL.SetShowForecast}?engagementId=${request.engagementId}&status=${request.status}`
  // );
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "POST",
    ControllerName: "Forecast",
    ActionName: FORECAST_URL.SetShowForecast,
    QueryString:
      "engagementId=" + request.engagementId + `&` + "status=" + request.status,
    Data: "",
  });
}

async function checkName({ request }) {
  // return await post(
  //   `${FORECAST_URL.checkName}?engagementId=${request.engagementId}&name=${request.name}&type=${request.type}`
  // );
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "POST",
    ControllerName: "Forecast",
    ActionName: FORECAST_URL.checkName,
    QueryString: "engagementId=" + request.engagementId + `&` + "name=" + request.name + `&` + "type=" + request.type,
    Data: "",
  });
}

async function allUpdateForecast({ allpayload }) {
  // return await post(`${FORECAST_URL.AllUpdateForecast}`, allpayload);
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "POST",
    ControllerName: "Forecast",
    ActionName: FORECAST_URL.AllUpdateForecast,
    QueryString: "",
    Data: allpayload,
  });
}
async function allUpdateTarget({ allpayload }) {
  // return await post(`${FORECAST_URL.AllUpdateTarget}`, allpayload);
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "POST",
    ControllerName: "Forecast",
    ActionName: FORECAST_URL.AllUpdateTarget,
    QueryString: "",
    Data: allpayload,
  });
}

async function SaveManualIntensityTargetSpecs({ payload }) {
  // return await post(`${FORECAST_URL.SaveManualIntensityTarget}`, payload);
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "POST",
    ControllerName: "Forecast",
    ActionName: FORECAST_URL.SaveManualIntensityTarget,
    QueryString: "",
    Data: payload,
  });
}

async function UpdateManualIntensityTargetSpecs({ payload }) {
  // return await post(`${FORECAST_URL.UpdateManualIntensityTarget}`, payload);
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "POST",
    ControllerName: "Forecast",
    ActionName: FORECAST_URL.UpdateManualIntensityTarget,
    QueryString: "",
    Data: payload,
  });
}

async function updateForeCastEndYearData({ request }) {
  // return await post(
  //   `${FORECAST_URL.UpdateForeCastEndYearData}?EngagementCode=${request.engagementCode}`
  // );
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "POST",
    ControllerName: "Forecast",
    ActionName: FORECAST_URL.UpdateForeCastEndYearData,
    QueryString: "EngagementCode=" + request.engagementCode,
    Data: "",
  });
}

async function getM2ForeCastDownloadData({ request }) {
  // return await get(
  //   `${FORECAST_URL.GetM2ForeCastDownloadData}?StartYear=${request.startYear}&EngagementCode=${request.engagementCode}`
  // );
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "GET",
    ControllerName: "Forecast",
    ActionName: FORECAST_URL.GetM2ForeCastDownloadData,
    QueryString:
      "StartYear=" +
      request.startYear +
      `&` +
      "EngagementCode=" +
      request.engagementCode,
    Data: "",
  });
}

async function getM2TargetDownloadData({ request }) {
  // return await get(
  //   `${FORECAST_URL.GetM2TargetDownloadData}?StartYear=${request.startYear}&EngagementCode=${request.engagementCode}`
  // );
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "GET",
    ControllerName: "Forecast",
    ActionName: FORECAST_URL.GetM2TargetDownloadData,
    QueryString:
      "StartYear=" +
      request.startYear +
      `&` +
      "EngagementCode=" +
      request.engagementCode,
    Data: "",
  });
}

export {
  getForecastRecords,
  saveForecastSpecification,
  editForecastSpecification,
  getTargetRecords,
  saveTargetSpecification,
  updateTargetSpecification,
  updateForecastSpecification,
  getForecastTargetByGroupId,
  getForecastByGroupId,
  updateBatchIdForTargetSpecification,
  updateBatchIdForForecastSpecification,
  deleteForecast,
  deleteTarget,
  getForecastSpecificationProcessingStatus,
  getTargetSpecificationProcessingStatus,
  allSaveForecastSpecification,
  allSaveTargetSpecification,
  applyForecast,
  applyTarget,
  setShowForecast,
  setShowTarget,
  checkName,
  allUpdateForecast,
  allUpdateTarget,
  updateForeCastEndYearData,
  SaveManualIntensityTargetSpecs,
  UpdateManualIntensityTargetSpecs,
  getM2ForeCastDownloadData,
  getM2TargetDownloadData,
};
