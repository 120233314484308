import React, { memo } from "react";
import { routes } from "../../routes/routes.constatns";
import { useSelector, useDispatch } from "react-redux";
import {
  getEngagementById,
  setDefaultLoader,
} from "../../store/slices/engagementSlice";

export default memo(({ node }) => {
  const dispatch = useDispatch();
  function onLinkClick(id) {
    dispatch(setDefaultLoader({ loader: false }));
    dispatch(getEngagementById({ id: id }));
    window.location.href = `${process.env.REACT_APP_URL_REG_ALL}${routes.createNewEngagement}?regionId=${node.data.regionID}&engagementId=${node.data.id}`;
  }
  return (
    <>
      <span
        className={`view-engagement-link ${
          node.data.activeFlag === 0 ? "view-engagement-link-inactive" : ""
        }`}
        onClick={() => {
          onLinkClick(node.data.id);
        }}
      >
        {node.data.engagementName}
      </span>
    </>
  );
});
