import { projectsLibraryHeader, scopes } from "../../util/constants";
import "./ProjectsLibrary.scss";
import { Select } from "../Select/Select";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getAllMasterSectors } from "../../store/slices/setupSlice";
import { ProjectLibraryItem } from "./components/ProjectLibraryItem/ProjectLibraryItem";
import { LoadingIndicator } from "../LoadingIndicator/LoadingIndicator";
import { getProjectLibrary } from "../../store/slices/decarbLibrarySlice";
import { SearchInput } from "../SearchInput/SearchInput";
import {
  MotifCard,
  MotifPagination,
  MotifPaginationSelect,
  MotifPaginationSelectItem,
} from "@ey-xd/motif-react";

export function ProjectsLibrary() {
  const dispatch = useDispatch();
  const allSectors = useSelector((state) => state.setup.allSectors);
  const projectLibrary = useSelector(
    (state) => state.decarbLibrary.projectLibrary
  );
  const projectLibraryLoading = useSelector(
    (state) => state.decarbLibrary.projectLibraryLoading
  );
  const [selectedSectors, setSelectedSectors] = useState([]);
  const [selectedScopes, setSelectedScopes] = useState([]);
  const [projectFilter, setProjectFilter] = useState("");
  const [applyProjectFilter, setApplyProjectFilter] = useState(false);
  const [filteredProjects, setFilteredProjects] = useState(projectLibrary);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  useEffect(() => {
    dispatch(getAllMasterSectors());
    dispatch(getProjectLibrary());
  }, [dispatch]);

  /* istanbul ignore next */
  useEffect(() => {
    if (projectLibrary?.[0]?.data?.length > 0) {
      let filteredProj = projectLibrary?.[0]?.data;
      let mxPage = filteredProj.length;

      if (projectFilter && applyProjectFilter) {
        filteredProj = filteredProj.filter((project) => {
          return project.projectName
            .toLowerCase()
            .includes(projectFilter.toLowerCase());
        });
        mxPage = filteredProj.length;
      }

      if (selectedScopes.length > 0) {
        filteredProj = filteredProj.filter((project) => {
          if (!project.scope) return false;
          var prjScopes = project.scope
            ?.split(",")
            .map((scope) => scope.trim());
          return selectedScopes.some((selectedScope) =>
            prjScopes.includes(selectedScope)
          );
        });
        mxPage = filteredProj.length;
      }

      if (selectedSectors.length > 0) {
        const selectedSectorsNames = allSectors
          .filter((sector) => selectedSectors.includes(sector.id))
          .map((sector) => sector.name.trim());
        filteredProj = filteredProj.filter((project) => {
          if (!project.sectorNames) return false;

          var prjScopes = project.sectorNames?.map((scope) => scope.trim());
          return selectedSectorsNames.some((selectedScope) =>
            prjScopes.includes(selectedScope)
          );
        });
        mxPage = filteredProj.length;
      }

      filteredProj = filteredProj.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
      );

      setFilteredProjects(filteredProj);
      setMaxPage(Math.ceil(mxPage / itemsPerPage));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedScopes.length,
    selectedSectors.length,
    applyProjectFilter,
    projectLibrary,
    projectFilter,
    itemsPerPage,
    currentPage,
  ]);

  /* istanbul ignore next */
  function onFilterProjectsChange(event) {
    const value = event.target.value;
    setProjectFilter(value);

    if (!value) {
      setApplyProjectFilter(false);
    }
  }

  return (
    <div className="projects-library">
      <div className="projects-library-header">
        <div className="projects-library-header-h">
          <span className="projects-library-header-title">
            {projectsLibraryHeader}
          </span>
        </div>
      </div>

      <div className="projects-library-filter">
        <SearchInput
          id="projects-search"
          placeholder="Search project name"
          value={projectFilter}
          onEnter={() => setApplyProjectFilter(true)}
          onChange={onFilterProjectsChange}
          onSearchButtonClick={() => setApplyProjectFilter(true)}
          hideClearButton
        />
        <div className="projects-library-filter-selects">
          <Select
            options={scopes}
            value={selectedScopes}
            multiple
            showSelectAllButton
            placeholder="Select scope"
            onChange={(event) => {
              setSelectedScopes(event);
            }}
          />
          <Select
            options={allSectors}
            value={selectedSectors}
            visibleOptions={3}
            multiple
            placeholder="Select sector"
            onChange={(event) => {
              setSelectedSectors(event);
            }}
          />
        </div>
      </div>
      <div
        className={`projects-library-projects-list ${
          projectLibraryLoading ? "projects-library-content-center" : ""
        } ${
          filteredProjects.length < 3
            ? "projects-library-content-normal"
            : "projects-library-content-space-between"
        }`}
      >
        <LoadingIndicator
          show={projectLibraryLoading}
          fullscreen={false}
          transparent
        >
          {filteredProjects.map((project, index) => {
            return <ProjectLibraryItem project={project} key={index} />;
          })}
          {filteredProjects.length % 3 !== 0 && (
            <MotifCard
              orientation="vertical"
              className="empty-card"
            ></MotifCard>
          )}
          {filteredProjects.length === 0 && !projectLibraryLoading && (
            <div className="projects-library-no-projects">
              <span>No projects found</span>
            </div>
          )}
        </LoadingIndicator>
      </div>
      <div className="projects-library-footer">
        <MotifPagination
          allowInput
          aria-label="Pagination Navigation"
          currentPage={currentPage}
          firstButtonProps={{
            title: "Go to first page",
          }}
          itemsPerPage={itemsPerPage}
          lastButtonProps={{
            title: "Go to last page",
          }}
          max={maxPage}
          min={1}
          nextButtonProps={{
            title: "Go to next page",
          }}
          onPageChange={(page) => setCurrentPage(page)}
          prevButtonProps={{
            title: "Go to previous page",
          }}
          role="navigation"
        >
          <MotifPaginationSelect>
            <MotifPaginationSelectItem onClick={() => setItemsPerPage(5)}>
              Show 5
            </MotifPaginationSelectItem>
            <MotifPaginationSelectItem
              selected
              onClick={() => setItemsPerPage(10)}
            >
              Show 10
            </MotifPaginationSelectItem>
            <MotifPaginationSelectItem onClick={() => setItemsPerPage(20)}>
              Show 20
            </MotifPaginationSelectItem>
            <MotifPaginationSelectItem onClick={() => setItemsPerPage(50)}>
              Show 50
            </MotifPaginationSelectItem>
          </MotifPaginationSelect>
        </MotifPagination>
      </div>
    </div>
  );
}
