import {
  MotifCard,
  MotifCardBody,
  MotifCardHeader,
  MotifChip,
  MotifTruncate,
} from "@ey-xd/motif-react";
import "./ProjectLibraryItem.scss";
import { IconButton } from "@mui/material";
import { ArrowRightIcon } from "../../../../icons";
import { currencyFormatter, numberFormatter } from "../../../../util/dateUtil";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../../routes/routes.constatns";
import {
  projectLibraryItemAbatement,
  projectLibraryItemMargCost,
  projectLibraryItemNetPresent,
  projectLibraryItemTechAbatement,
} from "../../../../util/constants";

export function ProjectLibraryItem({ project }) {
  const navigate = useNavigate();

  /* istanbul ignore next */
  function onShowProjectDetailsClick() {
    navigate(`${routes.decarbProjectsLibraryDetails}`, {
      state: {
        projectId: project.projectID,
      },
      gestureEnabled: false,
    });
  }

  return (
    <MotifCard orientation="vertical">
      <MotifCardHeader variant="alt" className="project-library-item-header">
        <div className="project-library-item-header-h">
          <span>{project.projectName}</span>
          <IconButton onClick={onShowProjectDetailsClick}>
            <ArrowRightIcon />
          </IconButton>
        </div>
        {project?.tagNames && project.tagNames.length ? (
          <div className="project-library-item-header-tags">
            {project.tagNames.map((tag, index) => {
              return (
                <MotifChip key={index} label={tag}>
                  {tag}
                </MotifChip>
              );
            })}
          </div>
        ) : (
          <>{"\u00A0"}</>
        )}
      </MotifCardHeader>
      <MotifCardBody className="project-library-item-body">
        {project.projectDescription ? (
          <MotifTruncate maxLines={3}>
            {project.projectDescription}
          </MotifTruncate>
        ) : (
          <>{"\u00A0"}</>
        )}
        <div className="project-library-item-body-footer">
          <div className="project-library-item-body-footer-row">
            <div className="project-library-item-body-footer-row-item">
              <span className="project-library-item-body-footer-row-item-header">
                {projectLibraryItemAbatement}
              </span>
              <span className="project-library-item-body-footer-row-item-value">
                {`${project.discountedPayBackPeriod} tCO\u{2082}e/yr`}
              </span>
            </div>
            <div className="project-library-item-body-footer-row-item project-library-item-body-footer-row-item-right">
              <span className="project-library-item-body-footer-row-item-header">
                {projectLibraryItemTechAbatement}
              </span>
              <span className="project-library-item-body-footer-row-item-value">
                {`${numberFormatter.format(project.technicalAbatement)}%`}
              </span>
            </div>
          </div>
          <div className="project-library-item-body-footer-row">
            <div className="project-library-item-body-footer-row-item">
              <span className="project-library-item-body-footer-row-item-header">
                {projectLibraryItemNetPresent}
              </span>
              <span className="project-library-item-body-footer-row-item-value">
                {currencyFormatter.format(project.netPresentValue)}
              </span>
            </div>
            <div className="project-library-item-body-footer-row-item project-library-item-body-footer-row-item-right">
              <span className="project-library-item-body-footer-row-item-header">
                {projectLibraryItemMargCost}
              </span>
              <span className="project-library-item-body-footer-row-item-value">
                {`$ ${numberFormatter.format(
                  project.marginalAbatementCost
                )} / tCO\u{2082}e`}
              </span>
            </div>
          </div>
        </div>
      </MotifCardBody>
    </MotifCard>
  );
}
