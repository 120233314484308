import React from "react";
import { Table } from "../../../Table/Table";
import "./ProjectResultsTable.scss";
import { decamelize } from "../../../../util/dateUtil";
import { useSelector } from "react-redux";

export function ProjectResultsTable() {
  const project = useSelector((state) => state.project);

  //Generate Dynamic table columns for abatement data
  function getAbatementColDefs() {
    let defaultCols = [
      {
        field: "Category",
        minWidth: 300,
        headerName: "",
        suppressMovable: true,
        pinned: "left",
        valueFormatter: (p) => decamelize(p.value, " "),
      },
      {
        field: "Unit",
        width: 100,
        headerName: "Unit",
        headerTooltip: "Unit",
        suppressMovable: true,
      },
    ];

    if (project?.projectAbatementData.length) {
      const firstRow = project.projectAbatementData[0];
      Object.keys(firstRow).forEach((key) => {
        if (key !== "Category" && key !== "Unit") {
          defaultCols.push({
            field: key,
            minWidth: 200,
            headerName: key,
            suppressMovable: true,
            valueFormatter: (value) => {
              if (value == null || value.value == null) {
                return "";
              }
              return value.value.toLocaleString();
            },
          });
        }
      });
    }

    return defaultCols;
  }

  return (
    <div className="project-results-table">
      <Table
        colDefs={
          project?.projectAbatementData.length > 0 ? getAbatementColDefs() : []
        }
        rowData={project.projectAbatementData}
        draggable={false}
      />
    </div>
  );
}
