import { AddIcon } from "../../icons";
import {
  CreateNewScenarioBtn,
  simulateHeaderDescription,
  simulateHeaderTitle,
  scenarioValidationMessage,
  correctM3inM4Message,
} from "../../util/constants";
import { Button, Table } from "../../components";
import "./Simulate.scss";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes/routes.constatns";
import { useDispatch, useSelector } from "react-redux";
import { getProjectScenarios } from "../../store/slices/simulateSlice";
import { useEffect, useState } from "react";
import customLink, { customButtonRenderer } from "./customEngagementLink";
import { formarDateTime } from "../../util/dateUtil";
import {
  setScenarioName,
  setScenarioDesc,
  setScenarioDetails,
  setIsScenarioValidated,
  isEditScenario,
} from "../../store/slices/simulateSlice";
import { getLockingData } from "../../store/slices/projectSlice";
import { SimulateDeleteModal } from "./SimulateDeleteModal";
import { exportToExcel } from "../../store/services/gmp.service";
import { getAllFlags } from "../../store/services/engagement.service";
import { createExcelFile } from "../../util/excelUtil";
import { syncData, datainSyncMessage } from "../../util/constants";
import { saveAs } from "file-saver";
import { LoadingIndicatorEmbed } from "../LoadingIndicator/LoadingIndicatorEmbed";
import { uploadFileToBlob } from "../../store/services/azureBlob.service";
import trigger from "../../assets/templates/trigger.txt";
export function Simulate() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const engagement = useSelector((state) => state.engagement.engagement);
  const user = useSelector((state) => state.user.user);
  const projectScenariosData = useSelector(
    (state) => state.simulate.projectScenariosData
  );
  const projectScenariosDataLoading = useSelector(
    (state) => state.simulate.projectScenariosDataLoading
  );
  const [lockedData, setLock] = useState();
  const [lockedBy, setLockedby] = useState();
  const selectedRegion = useSelector(
    (state) => state.engagement.selectedRegion
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [projectToDelete, setProjectToDelete] = useState("");
  const [isExportM4DataInProgress, setIsExportM4DataInProgress] =
    useState(false);
  const [syncDatainProgress, setSyncDataInProgress] = useState(false);
  const [showSyncButton, setShowSyncDataBtn] = useState(false);
  const [showDataSyncMessage, setdataSyncMessage] = useState(false);
  const [correctM3Message, setCorrectM3Message] = useState(false);
  const [isDataNotinSyncLoader, setisDataNotinSyncLoader] = useState(false);
  const [isDataNotinSync, setIsDataNotinSync] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isDisableCreateScenarioBtn, setDisableCreateScenarioBtn] =
    useState(false);
  let refreshIntervalId = undefined;
  let refreshIntervalCount = 0;

  useEffect(() => {
    if (engagement?.id) {
      getM2Flags();
    }
  }, []);

  useEffect(() => {
    if (projectScenariosData) {
      setTableData(projectScenariosData);
    }
  }, [projectScenariosData]);

  // function to show sync data button
  const getM2Flags = async () => {
    setTableData([]);
    let request = {
      engagementId: engagement.id,
      tableName: "Engagement",
    };
    const data = await getAllFlags({ request });
    if (data.m1_Reuploaded === "Y") {
      setShowSyncDataBtn(false);
    } else if (
      (data.m3_SyncEnabled === "Y" &&
        data.scenario_DataInSyncwithM3_flag === "N") ||
      data.targetInSyncwithM2_flag === "N"
    ) {
      setShowSyncDataBtn(true);
      setdataSyncMessage(true);
      fetchData();
    } else if (data.m4_SyncEnabled === "Y") {
      setShowSyncDataBtn(true);
      setdataSyncMessage(true);
      fetchData();
    } else {
      fetchData();
    }
    if (data.m4DataInSyncWithM2_flag === "N") {
      // setSyncDataInProgress(true);
      setIsDataNotinSync(true);
      // if (data.m4_SyncEnabled === "Y") {
      //   setdataSyncMessage(true);
      // }
      // else if (data.m4_SyncEnabled === "N") {
      //   setCorrectM3Message(true);
      // }
    }
    if (data.m1_Reuploaded === "Y") {
      setSyncDataInProgress(true);
      setDisableCreateScenarioBtn(true);
      setCorrectM3Message(true);
    } else {
      setDisableCreateScenarioBtn(false);
    }
  };

  // Get all scenario details for table
  const fetchData = async () => {
    dispatch(
      getProjectScenarios({
        engagementId: engagement.id,
        createdBy: user.username,
      })
    );
  };

  // Starting the data sync process by calling syncM3andM2data on click of sync Button
  const startDataSync = async () => {
    setdataSyncMessage(false);
    setisDataNotinSyncLoader(true);
    setSyncDataInProgress(true);
    setIsDataNotinSync(true);
    let request = {
      engCode: engagement.engagementCode,
      moduleName: "M4",
    };
    // const data = await syncModulebyData({ request });
    await uploadFileToBlob({
      file: trigger,
      moduleName: "M4_SyncData_Trigger",
      fileName: `TriggerSync_${engagement.engagementCode}_${
        user.username
      }_${formarDateTime(new Date(), "ddMMyyyy HH:mm:ss")}.txt`,
    });
    checkDatainSync();
  };

  // Checking Data in Sync flag status
  const checkDatainSync = async () => {
    try {
      refreshIntervalId = window.setInterval(checkM2Flags, 2000);
    } catch (error) {
      window.clearInterval(refreshIntervalId);
      refreshIntervalId = undefined;
      setisDataNotinSyncLoader(false);
      setSyncDataInProgress(false);
    }
  };

  // function to check M1 and M2 flags
  async function checkM2Flags() {
    // setdataSyncMessage(true);
    refreshIntervalCount += 1;
    let request = {
      engagementId: engagement.id,
      tableName: "Engagement",
    };
    const data = await getAllFlags({ request });
    if (data.scenario_DataInSyncwithM3_flag === "Y") {
      if (refreshIntervalId) {
        window.clearInterval(refreshIntervalId);
        refreshIntervalId = undefined;
        refreshIntervalCount = 0;
      }
      setTimeout(() => {
        setisDataNotinSyncLoader(false);
        setdataSyncMessage(false);
        setIsDataNotinSync(false);
        setSyncDataInProgress(false);
        setShowSyncDataBtn(false);
        fetchData();
      }, 5000);
    }
  }

  // On click of create new scenario
  function handleClick() {
    dispatch(isEditScenario({ editScenario: false }));
    dispatch(setScenarioDetails({ scenarioID: "" }));
    dispatch(setScenarioName({ name: "" }));
    dispatch(setScenarioDesc({ descrp: "" }));
    navigate(`${routes.createNewScenario}`, {
      state: {
        engagementId: engagement.id,
        regionId: selectedRegion.id,
      },
      gestureEnabled: false,
    });
  }

  // to get a locked user flags m4
  useEffect(() => {
    if (engagement.id) {
      const request = {
        LockedBy: user.username,
        ModuleName: "Engagement",
        EngagementID: engagement.id,
      };

      dispatch(getLockingData(request)).then((action) => {
        if (action) {
          const lockedData = action.payload.data.lockStatus;
          const lockedBy = action.payload.data.lockedBy;
          setLock(lockedData);
          setLockedby(lockedBy);
        }
      });
    }
  }, [dispatch]);

  // On delete project scenario
  function onDeleteProjectScenarioClick(id) {
    setIsModalVisible(true);
    setProjectToDelete(id);
  }

  function onDeleteProjectScenarioClose() {
    setIsModalVisible(false);
    setProjectToDelete("");
  }

  function onViewProjectScenarioClick(data) {
    dispatch(isEditScenario({ editScenario: true }));
    dispatch(setIsScenarioValidated({ scenarioValidate: data.isValidated }));
    dispatch(setScenarioDetails({ scenarioID: data.scenarioID }));
    navigate(`${routes.createNewScenario}`, {
      state: {
        engagementId: engagement.id,
        regionId: selectedRegion.id,
        scenarioId: data.scenarioID,
      },
    });
  }

  // Function to move numbers to end
  function moveNumberKeysToEnd(obj) {
    let keys = Object.keys(obj);
    let result = {};

    // First, add keys that are not numbers
    for (let key of keys) {
      if (isNaN(key)) {
        result[key] = obj[key];
      }
    }

    for (let key of keys) {
      if (!isNaN(key)) {
        result[`zvalueToReplace${key}`] = obj[key];
      }
    }

    return result;
  }

  // Function to call on export click
  async function onExportClick(data) {
    setIsExportM4DataInProgress(true);
    const request = {
      engagementCode: engagement.engagementCode,
      startYear: parseInt(engagement.mostRecentYear),
      projectName: "test",
      module: "M4",
      scenarioName: data.scenarioName,
    };

    try {
      const response = await exportToExcel({ request });

      let exportData = [];

      Object.keys(response).forEach((key) => {
        const data = response[key].map((item) => moveNumberKeysToEnd(item));
        exportData.push({ data: data, name: key });
      });

      const m3DataForExportBlob = await createExcelFile(exportData);

      const exportM3DataFileName = `EYDecarb_${
        engagement.engagementCode
      }_Module4_ExportedM4Data_${formarDateTime(
        new Date(),
        "ddMMyyyy HH:mm:ss"
      )}.xlsx`;
      saveAs(m3DataForExportBlob, exportM3DataFileName);

      setIsExportM4DataInProgress(false);
    } catch (error) {
      console.error(error);
      setIsExportM4DataInProgress(false);
    }
  }

  return (
    <div className="simulate">
      <div className="simulate-header">
        <div className="simulate-header-h">
          <span className="simulate-header-main">{simulateHeaderTitle}</span>
          <span className="simulate-header-desc">
            {simulateHeaderDescription}
          </span>
        </div>
        <div className="simulate-header-buttons">
          {showSyncButton && (
            <Button
              onClick={startDataSync}
              className={"simulate-header-sync-scenario"}
              variant="primary-alt"
              disabled={isDataNotinSyncLoader}
            >
              <AddIcon />
              {syncData}
            </Button>
          )}
          <Button
            onClick={handleClick}
            className={"simulate-header-new-scenario"}
            variant="primary-alt"
            disabled={isDisableCreateScenarioBtn}
          >
            <AddIcon />
            {CreateNewScenarioBtn}
          </Button>
        </div>
      </div>
      {isDataNotinSyncLoader && (
        <div>
          <LoadingIndicatorEmbed show={isDataNotinSyncLoader} />
          <span className="data-sync-message">{datainSyncMessage}</span>
        </div>
      )}
      {showDataSyncMessage && (
        <div className="analyse-validation-message">
          <span>{scenarioValidationMessage}</span>
        </div>
      )}
      {correctM3Message && (
        <div className="analyse-validation-message">
          <span>{correctM3inM4Message}</span>
        </div>
      )}
      <div className="simulate-table">
        <LoadingIndicatorEmbed
          show={projectScenariosDataLoading || isExportM4DataInProgress}
        >
          <Table
            rowData={tableData}
            colDefs={[
              {
                field: "scenarioName",
                minWidth: 100,
                headerName: "Scenario name",
                headerTooltip: "Scenario name",
                suppressMovable: true,
                pinned: "left",
                cellRenderer: customLink,
                cellRendererParams: () => {
                  return {
                    onViewProjectScenarioClick: onViewProjectScenarioClick,
                    isDataNotinSync: isDataNotinSync,
                  };
                },
              },
              {
                field: "numberOfProjects",
                minWidth: 200,
                flex: 1,
                headerName: "Number of projects",
                headerTooltip: "Number of projects",
                suppressMovable: true,
                valueFormatter: (value) => {
                  if (value == null || value.value == null) {
                    return "";
                  }
                  return value.value === 1
                    ? `${value.value.toLocaleString()} project`
                    : `${value.value.toLocaleString()} projects`;
                },
              },
              {
                field: "averageAnnualabatement",
                minWidth: 280,
                flex: 1,
                headerName: "Average annual abatement (tCO\u{2082}e/yr)",
                headerTooltip: "Average annual abatement (tCO\u{2082}e/yr)",
                suppressMovable: true,
                valueFormatter: (value) => {
                  if (value == null || value.value == null) {
                    return "";
                  }
                  return value.value.toLocaleString();
                },
              },
              {
                field: "technicalabatementpotential",
                minWidth: 250,
                flex: 1,
                headerName: "Technical abatement potential (%)",
                headerTooltip: "Technical abatement potential (%)",
                suppressMovable: true,
                valueFormatter: (value) => {
                  if (value == null || value.value == null) {
                    return "";
                  }
                  return value.value.toLocaleString();
                },
              },
              {
                field: "netpresentvalue",
                minWidth: 200,
                flex: 1,
                headerName: "Net present value ($)",
                headerTooltip: "Net present value ($)",
                suppressMovable: true,
                valueFormatter: (value) => {
                  if (value == null || value.value == null) {
                    return "";
                  }
                  return value.value.toLocaleString();
                },
              },
              {
                field: "marginalabatementcost",
                minWidth: 250,
                flex: 1,
                headerName: "Marginal abatement cost ($/tCO\u{2082}e)",
                headerTooltip: "Marginal abatement cost ($/tCO\u{2082}e)",
                suppressMovable: true,
                valueFormatter: (value) => {
                  if (value == null || value.value == null) {
                    return "";
                  }
                  return value.value.toLocaleString();
                },
              },
              {
                field: "createdDate",
                minWidth: 200,
                flex: 1,
                headerName: "Date created",
                headerTooltip: "Date created",
                suppressMovable: true,
                valueFormatter: (value) => {
                  if (value == null || value.value == null) {
                    return "";
                  }

                  return formarDateTime(new Date(value.value), "dd MMMM yyyy");
                },
              },
              {
                field: "actions",
                maxWidth: 100,
                headerName: "",
                suppressMovable: true,
                cellRenderer: customButtonRenderer,
                cellRendererParams: () => {
                  return {
                    onExportClick: onExportClick,
                    onDeleteProjectScenarioClick: onDeleteProjectScenarioClick,
                  };
                },
              },
            ]}
            draggable={false}
            rowClassRules={{
              "scenario-table-pending-validation": (params) => {
                return params.data.isValidated === "N";
              },
              // "scenario-table-data-sync-pending": (params) => {
              //   return params.data.dataInSyncwithM3_flag === "N";
              // },
              "scenario-table-data-sync-pending": (params) => {
                return syncDatainProgress === true
                  ? true
                  : params.data.dataInSyncwithM3_flag === "N" ||
                    params.data.gmpInSyncwithM3_flag === "N"
                  ? true
                  : false;
              },
            }}
          />
        </LoadingIndicatorEmbed>
      </div>
      <SimulateDeleteModal
        isModalVisible={isModalVisible}
        onClose={onDeleteProjectScenarioClose}
        projectToDelete={projectToDelete}
      />
    </div>
  );
}
