import { useDispatch, useSelector } from "react-redux";
import { WarningIcon } from "../../icons";
import { deleteScenario } from "../../store/slices/simulateSlice";
import { CancelBtn } from "../../util/constants";
import { Button } from "../Button/Button";
import { Modal } from "../Modal/Modal";

function ModalBody() {
  return (
    <span className="view-endagement-delete-modal-body">
      {
        "Are you certain you want to delete the scenario? Once you select 'Yes' the scenario will be irretrievable."
      }
    </span>
  );
}

function ModalFooter({ handleCancelClick, onConfirm }) {
  return (
    <div className="view-endagement-delete-modal-footer">
      <Button variant="secondary" onClick={handleCancelClick}>
        {CancelBtn}
      </Button>
      <Button onClick={onConfirm} variant="primary">
        {"Yes"}
      </Button>
    </div>
  );
}

export function SimulateDeleteModal({
  isModalVisible,
  onClose,
  projectToDelete,
}) {
  const dispatch = useDispatch();
  const engagement = useSelector((state) => state.engagement.engagement);

  function onConfirm() {
    dispatch(
      deleteScenario({
        engagementCode: engagement.engagementCode,
        scenarioId: projectToDelete,
      })
    );
    onClose();
  }

  return (
    <Modal
      show={isModalVisible}
      onClose={onClose}
      header={
        <div className="file-version-history-modal-header">
          <div className="motif-fab">
            <WarningIcon />
          </div>
          <span>{"Delete scenario"}</span>
        </div>
      }
      body={<ModalBody />}
      footer={<ModalFooter handleCancelClick={onClose} onConfirm={onConfirm} />}
    />
  );
}
