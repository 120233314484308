import { useNavigate } from "react-router-dom";
import { routes } from "../../routes/routes.constatns";
import { Button, Table } from "../../components";
import {
  inputCustomRenderer,
  customButtonRenderer,
  customTextRenderer,
} from "./InputCutomRenderer";
import "./ViewEngagement.scss";
import { useEffect, useState } from "react";
import {
  GetAllEngagements,
  updateEngagement,
} from "../../store/services/engagement.service";
import EngagementLink from "./customEngagementLink";
import { useSelector } from "react-redux";
import { AddIcon } from "../../icons";
import { RightArrowIcon } from "../../icons";
import {
  TableHeaderClientName,
  TableHeaderEngagementCode,
  TableHeaderEngagementName,
  TableHeaderEngagementStatus,
  CreateNewEngBtn,
  engDash,
} from "../../util/constants";
import { ViewEngagementDeleteModal } from "./ViewEngagementDeleteModal";
import {
  MotifCard,
  MotifCardFooter,
  MotifCardBody,
  MotifChip,
} from "@ey-xd/motif-react";
import { SelectRegionModal } from "../SelectRegionModal/SelectRegionModal";
import { LoadingIndicatorEmbed } from "../LoadingIndicator/LoadingIndicatorEmbed";
import { dropEngagementState } from "../../store/slices/engagementSlice";

export function ViewEngagement() {
  const navigate = useNavigate();
  const [rowData, setRowData] = useState([]);
  const steps = useSelector((state) => state.stepper.steps);
  const selectedRegion = useSelector(
    (state) => state.engagement.selectedRegion
  );
  const regionList = useSelector((state) => state.engagement.regionList);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSelectRegionVisible, setIsSelectRegionVisible] = useState(false);
  const [engagementToDelete, setEngagementToDelete] = useState("");
  const user = useSelector((state) => state.user.user);
  const [isDataLoadInProgress, setIsDataLoadInProgress] = useState(false);
  const [engagementData, setEngagementData] = useState([]);
  const [externalFlag, setExternalFlag] = useState([]);
  /* istanbul ignore next */
  function handleClick() {
    setIsSelectRegionVisible(true);
  }

  async function fetchData() {
    setIsDataLoadInProgress(true);
    dropEngagementState();
    try {
      const request = {
        email: user.username,
      };
      const data = await GetAllEngagements({ request });
      setEngagementData(data[0].data);
      setExternalFlag(data[0].externalUserFlag);
      setRowData(data[0].data);
    } catch (error) {
      console.error(error);
    }

    setIsDataLoadInProgress(false);
  }

  useEffect(() => {
    if (regionList.length && steps.length) {
      setIsDataLoadInProgress(true);
      fetchData();
      dropEngagementState();
    }
  }, [regionList.length, steps.length]);
  useEffect(() => {
    dropEngagementState();
  }, []);

  /* istanbul ignore next */
  function onDeleteEngagementClick(id) {
    setIsModalVisible(true);
    setEngagementToDelete(id);
  }

  /* istanbul ignore next */
  function onDeleteEngagementClose() {
    setIsModalVisible(false);
    setEngagementToDelete("");
  }

  /* istanbul ignore next */
  async function onDeleteEngagementConfirm() {
    const item = rowData.find((item) => item.id === engagementToDelete);
    item.activeFlag = 0;
    item.updatedDate = new Date().toLocaleString();

    const request = {
      id: engagementToDelete,
      engagementName: item.engagementName,
      engagementCode: item.engagementCode,
      clientName: item.clientName,
      createdBy: item.createdBy,
      activeFlag: item.activeFlag,
      updatedDate: item.updatedDate,
      updatedBy: user.username,
    };

    var apiRegionUrl = regionList.find((x) => x.id === item.regionID).apiUrl;

    await updateEngagement({ request, apiRegionUrl });

    setIsModalVisible(false);
    setEngagementToDelete("");

    const engagementToDeleteIndex = rowData.findIndex(
      (item) => item.id === engagementToDelete
    );
    rowData[engagementToDeleteIndex] = item;
    setRowData(
      rowData.sort((a, b) => {
        return b.activeFlag - a.activeFlag;
      })
    );
  }

  const renderCards = () => {
    return engagementData?.map((element) => {
      return (
        <div className="motif-col-xs-4 motif-col-md-2 motif-card-head">
          <MotifCard className="card-header">
            <MotifCardBody>
              <MotifChip
                className="chip-title"
                title="Chip Title"
                variant={element.activeFlag === 0 ? "warning" : "success"}
              >
                {element.activeFlag === 0 ? "Closed" : "Active"}
              </MotifChip>
              <h4 className="body-content">{element.engagementName} </h4>
            </MotifCardBody>
            <MotifCardFooter>
              <div
                className="motif-card-footer-right"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  {(element.setupStepID === 1 || 2 || 3 || 4) && (
                    <button
                      className="transparent-button"
                      onClick={() => {
                        navigate(`/${routes.powerBI}`, {
                          state: {
                            datasetId:
                              process.env.REACT_APP_POWER_BI_DATASET_ID_M1,
                            reportId:
                              process.env.REACT_APP_POWER_BI_REPORT_ID_M1,
                            engagementId: element.id,
                            engagementCode: element.engagementCode,
                            regionId: selectedRegion.id,
                          },
                          gestureEnabled: false,
                        });
                      }}
                    >
                      Manage
                      <span>
                        <RightArrowIcon />
                      </span>
                    </button>
                  )}
                  {(element.setupStepID === 2 || 3 || 4) && (
                    <button
                      className="transparent-button"
                      onClick={() => {
                        navigate(`/${routes.powerBI}`, {
                          state: {
                            datasetId:
                              process.env.REACT_APP_POWER_BI_DATASET_ID_M2,
                            reportId:
                              process.env.REACT_APP_POWER_BI_REPORT_ID_M2,
                            engagementId: element.id,
                            engagementCode: element.engagementCode,
                            regionId: selectedRegion.id,
                          },
                          gestureEnabled: false,
                        });
                      }}
                    >
                      Forecast
                      <span>
                        <RightArrowIcon />
                      </span>
                    </button>
                  )}
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {(element.setupStepID === 3 || 4) && (
                    <button
                      className="transparent-button"
                      onClick={() => {
                        navigate(`/${routes.powerBI}`, {
                          state: {
                            datasetId:
                              process.env.REACT_APP_POWER_BI_DATASET_ID_M3,
                            reportId:
                              process.env.REACT_APP_POWER_BI_REPORT_ID_M3,
                            engagementId: element.id,
                            engagementCode: element.engagementCode,
                            regionId: selectedRegion.id,
                          },
                          gestureEnabled: false,
                        });
                      }}
                    >
                      Analyse
                      <span>
                        <RightArrowIcon />
                      </span>
                    </button>
                  )}
                  {element.setupStepID === 4 && (
                    <button
                      className="transparent-button"
                      onClick={() => {
                        navigate(`/${routes.powerBI}`, {
                          state: {
                            datasetId:
                              process.env.REACT_APP_POWER_BI_DATASET_ID_M4,
                            reportId:
                              process.env.REACT_APP_POWER_BI_REPORT_ID_M4,
                            engagementId: element.id,
                            engagementCode: element.engagementCode,
                            regionId: selectedRegion.id,
                          },
                          gestureEnabled: false,
                        });
                      }}
                    >
                      Simulate
                    </button>
                  )}
                </div>
              </div>
            </MotifCardFooter>
          </MotifCard>
        </div>
      );
    });
  };

  return (
    <div className="view-engagement">
      <div className="view-engagement-header">
        <div className="view-engagement-header-h">
          <span className="view-engagement-header-title">{engDash}</span>
        </div>
        {externalFlag === 0 && (
          <Button
            onClick={handleClick}
            className={"view-engagement-header-new-project"}
            variant="primary-alt"
          >
            <AddIcon />
            {CreateNewEngBtn}
          </Button>
        )}
      </div>
      <LoadingIndicatorEmbed show={isDataLoadInProgress}>
        <div className="view-engagement-table">
          {externalFlag === 0 && (
            <Table
              colDefs={[
                {
                  field: "engagementName",
                  cellRenderer: EngagementLink,
                  headerName: TableHeaderEngagementName,
                  headerTooltip: TableHeaderEngagementName,
                  sortable: true,
                  flex: 0.5,
                  suppressMovable: true,
                  pinned: "left",
                },
                {
                  field: "engagementCode",
                  headerName: TableHeaderEngagementCode,
                  headerTooltip: TableHeaderEngagementCode,
                  flex: 0.5,
                  minWidth: 200,
                  cellRenderer: customTextRenderer,
                  suppressMovable: true,
                },
                {
                  field: "clientName",
                  headerName: TableHeaderClientName,
                  headerTooltip: TableHeaderClientName,
                  flex: 0.4,
                  minWidth: 200,
                  cellRenderer: customTextRenderer,
                  suppressMovable: true,
                },
                {
                  field: "regionName",
                  headerName: "Hosting location",
                  headerTooltip: "Hosting location",
                  flex: 0.4,
                  minWidth: 150,
                  suppressMovable: true,
                },
                {
                  field: "engagementStatus",
                  cellRenderer: inputCustomRenderer,
                  cellRendererParams: () => {
                    return {
                      steps: steps,
                    };
                  },
                  headerComponentParams: {
                    enableSorting: false,
                    showFilter: false,
                  },
                  headerName: TableHeaderEngagementStatus,
                  headerTooltip: TableHeaderEngagementStatus,
                  flex: 1.5,
                  minWidth: 600,
                  suppressMovable: true,
                },
                {
                  cellRenderer: customButtonRenderer,
                  cellRendererParams: () => {
                    return {
                      onDeleteEngagementClick: onDeleteEngagementClick,
                    };
                  },
                  field: "actions",
                  headerName: "",
                  width: 80,
                },
              ]}
              rowData={rowData}
              rowClassRules={{
                "view-engagement-closed-project": (params) => {
                  return params.data.activeFlag === 0;
                },
              }}
              draggable={false}
              loading={isDataLoadInProgress}
            />
          )}

          {externalFlag === 1 && (
            <div className="motif-row" style={{ marginTop: "10px" }}>
              {renderCards()}
            </div>
          )}
        </div>
      </LoadingIndicatorEmbed>
      <ViewEngagementDeleteModal
        isModalVisible={isModalVisible}
        onClose={onDeleteEngagementClose}
        onConfirm={onDeleteEngagementConfirm}
      />
      <SelectRegionModal
        isModalVisible={isSelectRegionVisible}
        onClose={() => setIsSelectRegionVisible(false)}
      />
    </div>
  );
}
