import { DeleteLogo } from "../../icons";
import { MotifIconButton } from "@ey-xd/motif-react";
import { memo } from "react";
import { useSelector } from "react-redux";

// Render Delete button in table
export default memo(({ node, onDeleteClick }) => {
  const user = useSelector((state) => state.user.user);
  const lockingData = useSelector((state) => state.project?.lockingData);

  return (
    <div>
      <MotifIconButton
        disabled={
          lockingData?.data?.lockStatus === 1 &&
          lockingData?.data?.lockedBy !== user.username
        }
        onClick={() => {
          onDeleteClick(node.data);
        }}
      >
        <DeleteLogo></DeleteLogo>
      </MotifIconButton>
    </div>
  );
});
