import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from 'react-router-dom';
import {
  dropEngagementState,
  dropSelectedRegion,
} from "../../store/slices/engagementSlice";
import { dropStepperState } from "../../store/slices/stepperSlice";
import { dropProjectState } from "../../store/slices/projectSlice";
import { dropSetupState } from "../../store/slices/setupSlice";
import { dropForecastState } from "../../store/slices/forecastSlice";
import { SessionTimeoutModal } from "./SessionTimeoutModal/SessionTimeoutModal";
import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { InteractionStatus } from "@azure/msal-browser";
import { dropSimulateState } from "../../store/slices/simulateSlice";
import { routes } from "../../routes/routes.constatns";
import { releaseAlllocks } from "../../store/services/engagement.service";

export function AutoLogout() {
  // const navigate = useNavigate();
  const { instance, accounts, inProgress } = useMsal();
  const dispatch = useDispatch();
  const [isSessionTimeoutModalVisible, setSessionTimeoutModalVisible] =
    useState(false);
  const user = useSelector((state) => state.user.user);

  let idleTimeout = 1000 * 60 * 10; //10 minutes
  let idleLogout = 1000 * 60 * 15; //15 Minutes
  let idleEvent;
  let idleLogoutEvent;

  const events = ["mousemove", "click", "keypress", "scroll", "load"];

  /**
   * @method sessionTimeout
   * This function is called with each event listener to set a timeout or clear a timeout.
   */
  const sessionTimeout = () => {
    if (!!idleEvent) clearTimeout(idleEvent);
    if (!!idleLogoutEvent) clearTimeout(idleLogoutEvent);

    idleEvent = setTimeout(
      () => setSessionTimeoutModalVisible(true),
      idleTimeout
    ); //show session warning modal.
    idleLogoutEvent = setTimeout(() => logOut(), idleLogout); //Call logged out on session expire.
  };

  /**
   * @method logOut
   * This function will destroy current user session.
   */
  const logOut = async () => {
    // Clear session storage
    sessionStorage.clear();

    // Clear local storage
    localStorage.clear();
    await releaseAlllocks({ request: { email: user.username } });
    dispatch(dropEngagementState());
    dispatch(dropStepperState());
    dispatch(dropProjectState());
    dispatch(dropSetupState());
    dispatch(dropForecastState());
    dispatch(dropSimulateState());
    dispatch(dropSelectedRegion());

    if (inProgress === InteractionStatus.None && accounts.length > 0) {
      instance
        .logoutRedirect({
          account: instance.getActiveAccount(),
          postLogoutRedirectUri: `${process.env.REACT_APP_URL_REG_ALL}${routes.home}`,
        })
        .catch((e) => {
          sessionStorage.clear();
          document.cookie = "msal.interaction.status=;";
          instance.logoutRedirect({
            postLogoutRedirectUri: `${process.env.REACT_APP_URL_REG_ALL}${routes.home}`,
          });
        });
    }
  };

  useEffect(() => {
    for (let e in events) {
      window.addEventListener(events[e], sessionTimeout);
    }

    return () => {
      for (let e in events) {
        window.removeEventListener(events[e], sessionTimeout);
      }
    };
  }, []);

  useEffect(() => {
    // Prevent back navigation
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      window.history.pushState(null, null, window.location.href);
    };
  }, []);

  return (
    <SessionTimeoutModal
      isModalVisible={isSessionTimeoutModalVisible}
      onCancel={() => {
        logOut();
      }}
      onConfirm={() => {
        setSessionTimeoutModalVisible(false);
        clearTimeout(idleEvent);
        clearTimeout(idleLogoutEvent);

        for (let e in events) {
          window.removeEventListener(events[e], sessionTimeout);
          window.addEventListener(events[e], sessionTimeout);
        }
      }}
    />
  );
}
