import { GMP_URL } from "./endpoints/gmp";
import { post } from "./base.service";

async function getParametersList({ request }) {
  return await post(
    // `${GMP_URL.GetParametersList}?engagementId=${request.engagementId}&variable_type=${request.variable_type}`
    `/WebApi/Invoke`, {
      ActionVerb: "GET",
      ControllerName: "GMP",
      ActionName: GMP_URL.GetParametersList,
      QueryString: 'engagementId=' + request.engagementId + `&` + 'variable_type=' + request.variable_type,
      Data: ""
    }
  );
}

async function getruleList({ request }) {
  return await post(
    // `${GMP_URL.GetRulesList}?projectID=${request.projectId}`
    `/WebApi/Invoke`, {
      ActionVerb: "GET",
      ControllerName: "GMP",
      ActionName: GMP_URL.GetRulesList,
      QueryString: 'projectID=' + request.projectId,
      Data: ""
    }
  );
}

async function saveDecarbGmpRuleMap({ request }) {
  return await post(
    // `${GMP_URL.SaveDecarbGmpRuleMap}`, request
    `/WebApi/Invoke`, {
      ActionVerb: "POST",
      ControllerName: "GMP",
      ActionName: GMP_URL.SaveDecarbGmpRuleMap,
      QueryString: "",
      Data: request
    }
  );
}

async function getGMPRulesMapData({ request }) {
  return await post(
    // `${GMP_URL.GetGMPRulesMapData}?projectID=${request.projectId}`
    `/WebApi/Invoke`, {
      ActionVerb: "GET",
      ControllerName: "GMP",
      ActionName: GMP_URL.GetGMPRulesMapData,
      QueryString: 'projectID=' + request.projectId,
      Data: ""
    }
  );
}

async function deleteGmpRule({ request }) {
  return await post(
    // `${GMP_URL.DeleteGmpRule}?ruleId=${request.ruleId}`
    `/WebApi/Invoke`, {
      ActionVerb: "Post",
      ControllerName: "GMP",
      ActionName: GMP_URL.DeleteGmpRule,
      QueryString: 'ruleId=' + request.ruleId,
      Data: ""
    }
  );
}

async function getGMPData({ request }) {
  return await post(
    // `${GMP_URL.GetGMPDataURL}?EngagementCode=${request.engagement}&ViewType=${request.viewType}`
    `/WebApi/Invoke`, {
      ActionVerb: "GET",
      ControllerName: "GMP",
      ActionName: GMP_URL.GetGMPDataURL,
      QueryString: 'EngagementCode=' + request.engagement + `&` + 'ViewType=' + request.viewType,
      Data: ""
    }
  );
}

async function deleteGMPDetail({ request }) {
  return await post(
    // `${GMP_URL.DeleteGMPDetail}?ParameterID=${request.ParameterID}&engagementCode=${request.engagementCode}&modifiedBy=${request.modifiedBy}`
    `/WebApi/Invoke`, {
      ActionVerb: "POST",
      ControllerName: "GMP",
      ActionName: GMP_URL.DeleteGMPDetail,
      QueryString: 'ParameterID=' + request.ParameterID  + `&` +  'engagementCode=' + request.engagementCode  + `&` +  'modifiedBy=' + request.modifiedBy               ,
      Data: ""
    }
  );
}

async function saveGMPData({ payload }) {
  return await post(
    // `${GMP_URL.SaveGMPData}`, payload
    `/WebApi/Invoke`, {
      ActionVerb: "POST",
      ControllerName: "GMP",
      ActionName: GMP_URL.SaveGMPData,
      QueryString: "",
      Data: payload
    }
  );
}

async function updateGMPDataManully({ payload }) {
  return await post(
    // `${GMP_URL.UpdateGMPDataManully}`, payload
    `/WebApi/Invoke`, {
      ActionVerb: "POST",
      ControllerName: "GMP",
      ActionName: GMP_URL.UpdateGMPDataManully,
      QueryString: "",
      Data: payload
    }
  );
}

async function getParameterMatch({ payload }) {
  return await post(
    // `${GMP_URL.GetParameterMatch}`, payload
    `/WebApi/Invoke`, {
      ActionVerb: "POST",
      ControllerName: "GMP",
      ActionName: GMP_URL.GetParameterMatch,
      QueryString: "",
      Data: payload
    }
  );
}

async function applyGMPRules({ request }) {
  return await post(
    // `${GMP_URL.ApplyGMPRules}?engCode=${request.engCode}&projectId=${request.projectId}`
    `/WebApi/Invoke`, {
      ActionVerb: "POST",
      ControllerName: "GMP",
      ActionName: GMP_URL.ApplyGMPRules,
      QueryString: 'engCode=' + request.engCode + `&` + 'projectId=' + request.projectId,
      Data: ""
    }
  );
}

async function exportToExcel({ request }) {
  return await post(
    // `${GMP_URL.ExportToExcel}?StartYr=${request.startYear}&EngagementCode=${request.engagementCode}&Projectname=${request.projectName}&Module=${request.module}&Scenarioname=${request.scenarioName}`
    `/WebApi/Invoke`, {
      ActionVerb: "POST",
      ControllerName: "GMP",
      ActionName: GMP_URL.ExportToExcel,
      QueryString: 'StartYr=' + request.startYear + `&` +  'EngagementCode=' + request.engagementCode + `&` + 'Projectname=' + request.projectName + `&` +   'Module=' + request.module + `&` + 'Scenarioname=' + request.scenarioName,
      Data: ""
    }
  );
}

export {
  getParametersList,
  getruleList,
  saveDecarbGmpRuleMap,
  getGMPRulesMapData,
  deleteGmpRule,
  getGMPData,
  deleteGMPDetail,
  saveGMPData,
  updateGMPDataManully,
  getParameterMatch,
  applyGMPRules,
  exportToExcel,
};
