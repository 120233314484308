import axios from "axios";

export async function get(url, token = null) {
  const config = {
    withCredentials: true,
    sameSite: "Strict",
    "X-Frame-Options": "DENY",
    "Content-Security-Policy": "frame-ancestors 'none'",
  };

  config.headers = token ? { Authorization: `Bearer ${token}` } : {};

  return await axios
    .get(`${url}`, config)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
      throw new Error("Error in response - " + res);
    })
    .catch((err) => {
      throw err;
    });
}

export async function post(url, data = null) {
  const config = {
    withCredentials: true,
    sameSite: "Strict",
    "X-Frame-Options": "DENY",
    "Content-Security-Policy": "frame-ancestors 'none'",
  };
  return await axios
    .post(`${url}`, data, config)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
      throw new Error("Error in response - " + res);
    })
    .catch((err) => {
      throw err;
    });
}

export async function getListCrossRegion(url, data) {
  const axiosResponse = await Promise.allSettled([
    axios.post(
      // `${process.env.REACT_APP_API_URL_REG_US}${url}`
      `/WebApi/Invoke`,
      {
        ActionVerb: "GET",
        ControllerName: data ? "Engagement" : "DecarbLibrary",
        ActionName: url,
        QueryString: data ? "CreatedBy=" + data : "",
        Data: "",
      }
    ),
  ]);

  let response = [];

  const validData = axiosResponse.map((res) => {
    if (res.status === "fulfilled") {
      return res.value.data;
    }

    if (res.status === "rejected") {
      console.error("Error in response", res);
    }
  });

  if (validData && validData.length) {
    response = validData.flat(1).filter((x) => x);
  }

  return response;
}
