import { EmissionsTable } from "./EmissionsTable/EmissionsTable";
import "./Emissions.scss";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { EmissionHeader } from "./EmissionsHeader/EmissionHeader";
import { getHierarchyLevelData } from "../../store/services/engagement.service";
import {
  setHierarchialData,
  setIsNextTabButtonDisabled,
  setUISelectedHeaderFilters,
  setselectedUIAllFilters,
} from "../../store/slices/projectSlice";
import { EmissionSimulate } from "./EmissionsSimulate/EmissionSimulate";
import { EmissionGraph } from "./EmissionGraph/EmissionGraph";
import { getProjectEmissionFilters } from "../../store/services/project.service";

export function Emissions({ onTabStepCompleted }) {
  const engagement = useSelector((state) => state.engagement.engagement);
  const project = useSelector((state) => state.project.project);
  const m3Step2Loader = useSelector((state) => state.project.graphTableLoader);
  const dispatch = useDispatch();
  const [showloader, setShowLoader] = useState(false);

  useEffect(() => {
    setShowLoader(m3Step2Loader);
  }, [m3Step2Loader]);

  useEffect(() => {
    if (project?.projectId) {
      getProjectEditDetails();
      onTabStepCompleted();
      hierarchyData();
      dispatch(setIsNextTabButtonDisabled({ isDisabled: false }));
    }
  }, [project?.projectId]);

  const getProjectEditDetails = async () => {
    const request = { projectID: project?.projectId };
    setEmissionFiltersSaved(request);
  };

  const setEmissionFiltersSaved = async (request) => {
    const filters = await getProjectEmissionFilters({ request });
    dispatch(
      setUISelectedHeaderFilters({
        selectedUIHeaderFilters: {
          scope: [...filters.scope],
          category_level1: filters.categoryLevel1.includes("All")
            ? []
            : [...filters.categoryLevel1],
          category_level2: filters.categoryLevel2.includes("All")
            ? []
            : [...filters.categoryLevel2],
          category_level3: filters.categoryLevel3.includes("All")
            ? []
            : [...filters.categoryLevel3],
          highestLevelFilter: filters.highestFilterSelected,
        },
      })
    );
    dispatch(
      setselectedUIAllFilters({
        selectedUIAllFilters: {
          scope: [...filters.scope],
          category_level1: filters.categoryLevel1.includes("All")
            ? []
            : [...filters.categoryLevel1],
          category_level2: filters.categoryLevel2.includes("All")
            ? []
            : [...filters.categoryLevel2],
          category_level3: filters.categoryLevel3.includes("All")
            ? []
            : [...filters.categoryLevel3],
          region_level1: [],
          region_level2: [],
          region_level3: [],
          entity_level1: [],
          entity_level2: [],
          entity_level3: [],
          entity_level4: [],
          custom_specification1: [],
          custom_specification2: [],
          custom_specification3: [],
          highestLevelFilter: filters.highestFilterSelected,
        },
      })
    );
  };

  //Fetching hierarchy data and creating redux state for it.
  const hierarchyData = async () => {
    const request = {
      engagement: engagement.engagementCode,
      clientName: engagement.clientName,
    };
    const data = await getHierarchyLevelData({ request });
    dispatch(setHierarchialData({ hierarchialData: data }));
  };

  return (
    <div className="emissions">
      <div className="emissions-header">
        <EmissionHeader />
      </div>
      <div className="emissions-chart-section">
        <EmissionSimulate />
        <EmissionGraph loader={showloader} />
      </div>
      <div className="emissions-body">
        <EmissionsTable loader={showloader} />
      </div>
    </div>
  );
}
