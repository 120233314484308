import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";
import HC_More from "highcharts/highcharts-more";
import "./SimulateWaterFallGraph.scss";
// import { useSelector } from "react-redux";
import { LoadingIndicatorEmbed } from "../../../../../LoadingIndicator/LoadingIndicatorEmbed";

export function SimulateWaterFallGraph({ loader, scenarioWaterFallData }) {
  HC_More(Highcharts);
  NoDataToDisplay(Highcharts);
  const [chartOptions, setChartOptions] = useState({});
  useEffect(() => {
    if (scenarioWaterFallData) {
      updateChartDataSet(scenarioWaterFallData);
    }
  }, [scenarioWaterFallData]);

  // Update chart with data set
  const updateChartDataSet = (data) => {
    let chartData = [];
    chartData = data;
    let graphDataArray = [];
    Object.keys(chartData).forEach((item) => {
      graphDataArray.push(chartData[item]);
    });
    var i;
    for (i = 0; i < graphDataArray.length; i++) {
      if (graphDataArray[i]["category"] === "BAU") {
        graphDataArray[i].name = graphDataArray[i]["category"];
        graphDataArray[i].isIntermediateSum = true;
        graphDataArray[i].color = "#B26100";
      } else if (graphDataArray[i]["category"] === "End State") {
        graphDataArray[i].name = graphDataArray[i]["category"];
        graphDataArray[i].isSum = true;
        graphDataArray[i].color = "#B26100";
      } else {
        graphDataArray[i].name = graphDataArray[i]["category"];
        graphDataArray[i].y = graphDataArray[i]["abetments"];
        graphDataArray[i].color =
          i === 0
            ? "#B26100"
            : graphDataArray[i]["abetments"] > 0
            ? "#229B9A"
            : "#5F5F72";
      }
      delete graphDataArray[i].abetments;
      delete graphDataArray[i].category;
    }
    setChartOptions({
      chart: {
        type: "waterfall",
      },
      title: {
        text: "",
      },

      xAxis: {
        type: "category",
        labels: {
          style: {
            color: "#afaeba",
          },
        },
      },

      yAxis: {
        title: {
          text: "Emissions (tCO\u{2082}e)",
          style: {
            color: "#afaeba",
          },
        },
        labels: {
          style: {
            color: "#afaeba",
          },
        },
      },
      plotOptions: {
        waterfall: {
          lineColor: "#FFF",
          lineWidth: 2,
        },
      },
      legend: {
        enabled: false,
        layout: "horizontal",
        align: "center",
        verticalAlign: "bottom",
      },

      tooltip: {
        pointFormat: "<b>{point.y}",
      },

      series: [
        {
          // upColor: Highcharts.getOptions().colors[1],
          color: "#FFFFFF",
          data: graphDataArray,
          dataLabels: {
            enabled: true,
            // format: "{divide y 1000}k",
          },
          pointPadding: 0,
        },
        // showInLegend: true
      ],
      noData: {
        style: {
          fontWeight: "100",
          fontSize: "15px",
          color: "#DEDEE2",
        },
        position: {
          align: "center",
          verticalAlign: "middle",
        },
        text: "No data available",
      },
    });
  };
  return (
    <div className="emissions-graph-section">
      <div className="emissions-graph-title"></div>
      <div className="emissions-graph-content">
        <div className="simulate-waterflow-graph-content">
          <LoadingIndicatorEmbed show={loader}>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
          </LoadingIndicatorEmbed>
          <div className="custom-legend">
            <ul className="circle-list">
              <li className="Increase">Increase</li>
              <li className="Decrease">Decrease</li>
              <li className="Total">Total</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
