export const SIMULATE_URL = {
  SaveScenario: `SaveScenario`,
  MapProjectsToScenario: `MapProjectsToScenario`,
  GetM4TrajectoryGraphData: `GetM4EmissionsTrajectoryGraphData`,
  GetEmissionData: `GetEmissionData`,
  GetM4WaterfallGraphData: `GetM4WaterfallGraphData`,
  GetM4MarginalAbatementCostGraphData: `GetM4MarginalAbatementCostGraphData`,
  GetBusinessCaseSummaryData: `GetBusinessCaseSummaryData`,
  GetAbatementCashflowData: `GetAbatementCashflowData`,
  DeleteScenarioDetail: `DeleteScenarioDetail`,
  GetScenarioDetails: `GetScenarioDetails`,
  UpdateScenarioDesc: `UpdateScenarioDescription`,
};
