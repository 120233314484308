import { useLocation, useNavigate } from "react-router-dom";
import "./ProjectDetails.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  dropDecarbLibraryState,
  getAbatementData,
  getBusinessCases,
  getBusinessRules,
  getGmpTable,
  getProjectLibrary,
} from "../../../../store/slices/decarbLibrarySlice";
import { useEffect, useState } from "react";
import { Button, LoadingIndicator, Table } from "../../..";
import { ArrowLeftIcon } from "../../../../icons";
import {
  MotifCard,
  MotifCardBody,
  MotifCardHeader,
  MotifChip,
  MotifChipGroup,
  MotifTooltip,
} from "@ey-xd/motif-react";
import { numberFormatter } from "../../../../util/dateUtil";
import CustomChipComponent from "./CustomChips";
import { GetProjectdetails } from "../../../../store/services/project.service";
import { getEngagement } from "../../../../store/services/engagement.service";
import { decamelize } from "../../../../util/dateUtil";
import {
  BackBtn,
  projectDetailsAbatementCashFlowCalcHeader,
  projectDetailsBusinessRules,
  projectDetailsBusinessRulesADUnit,
  projectDetailsBusinessRulesADValue,
  projectDetailsBusinessRulesChangeType,
  projectDetailsBusinessRulesCumulative,
  projectDetailsBusinessRulesCust1,
  projectDetailsBusinessRulesCust2,
  projectDetailsBusinessRulesCust3,
  projectDetailsBusinessRulesDataType,
  projectDetailsBusinessRulesEfValue,
  projectDetailsBusinessRulesEndYear,
  projectDetailsBusinessRulesEnt1,
  projectDetailsBusinessRulesEnt2,
  projectDetailsBusinessRulesEnt3,
  projectDetailsBusinessRulesEnt4,
  projectDetailsBusinessRulesHierarchy,
  projectDetailsBusinessRulesName,
  projectDetailsBusinessRulesReg1,
  projectDetailsBusinessRulesReg2,
  projectDetailsBusinessRulesReg3,
  projectDetailsBusinessRulesRuleName,
  projectDetailsBusinessRulesScope,
  projectDetailsBusinessRulesStartYear,
  projectDetailsBusinessRulesSubcat1,
  projectDetailsBusinessRulesSubcat2,
  projectDetailsBusinessRulesSubcat3,
  projectDetailsBusinessRulesTransferAD,
  projectDetailsBusinessRulesUnit,
  projectDetailsBusinessRulesUnitOp,
  projectDetailsBusinessRulesValue,
  projectDetailsCategoryHeader,
  projectDetailsFinancialsHeader,
  projectDetailsItemDPP,
  projectDetailsItemMargCost,
  projectDetailsItemNetPresent,
  projectDetailsItemRateOfReturn,
  projectDetailsItemSImplePayback,
  projectDetailsItemTechAbatement,
  projectDetailsTags,
  projectDetailsType,
  projectDetailsUnitHeader,
  projectResultsBusinessCaseSummaryHeader,
} from "../../../../util/constants";

export function ProjectDetails() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const projectLibrary = useSelector(
    (state) => state.decarbLibrary.projectLibrary
  );
  const summary = useSelector((state) => state.decarbLibrary.summary);

  const summaryLoading = useSelector(
    (state) => state.decarbLibrary.summaryLoading
  );
  const businessRules = useSelector(
    (state) => state.decarbLibrary.businessRules
  );
  const businessRulesLoading = useSelector(
    (state) => state.decarbLibrary.businessRulesLoading
  );
  const abatementData = useSelector(
    (state) => state.decarbLibrary.abatementData
  );
  const financialData = useSelector(
    (state) => state.decarbLibrary.financialData
  );
  const abatementFinancialDataLoading = useSelector(
    (state) => state.decarbLibrary.abatementDataLoading
  );
  const gmpData = useSelector((state) => state.decarbLibrary.gmpData);
  const gmpDataLoading = useSelector(
    (state) => state.decarbLibrary.gmpDataLoading
  );
  const [selectedProject, setSelectedProject] = useState({});
  const [projectScopes, setProjectScopes] = useState([]);

  const projectTypes = {
    1: "Reduce",
    2: "Replace",
    3: "Compensate",
  };

  useEffect(() => {
    if (state.projectId) {
      if (projectLibrary && projectLibrary.length) {
        const proj = projectLibrary[0].data.find(
          (x) => x.projectID === state.projectId
        );
        setSelectedProject(proj);
        setProjectScopes(proj?.scope?.split(",") ?? []);
        dispatch(getBusinessCases({ projectId: state.projectId }));
        dispatch(getBusinessRules({ projectId: state.projectId }));
        getFinAbatementData();
      } else {
        dispatch(getProjectLibrary());
      }
    }
  }, [dispatch, state.projectId, projectLibrary]);

  async function getFinAbatementData() {
    const project = await GetProjectdetails({
      request: { projectID: state.projectId },
    });
    const engagement = await getEngagement({
      request: { id: project.data.engagementID },
    });
    dispatch(
      getAbatementData({
        engagementCode: engagement.data.engagementCode,
        projectID: state.projectId,
        pageName: "library",
      })
    );
    dispatch(
      getGmpTable({
        engagementCode: engagement.data.engagementCode,
        projectID: state.projectId,
      })
    );
  }

  function onPreviousPageClick() {
    dispatch(dropDecarbLibraryState());
    window.history.replaceState({}, "");
    navigate(-1);
  }

  function getAbatementColDefs() {
    let defaultCols = [
      {
        field: "Category",
        minWidth: 300,
        headerName: "",
        suppressMovable: true,
        pinned: "left",
        valueFormatter: (p) => decamelize(p.value, " "),
        sortable: false,
      },
      {
        field: "Unit",
        width: 100,
        headerName: projectDetailsUnitHeader,
        suppressMovable: true,
        sortable: false,
      },
    ];

    if (financialData.length) {
      const firstRow = financialData[0];
      Object.keys(firstRow).forEach((key) => {
        if (key !== "Category" && key !== "Unit") {
          defaultCols.push({
            field: key,
            minWidth: 200,
            headerName: key,
            suppressMovable: true,
            sortable: false,
            valueFormatter: (value) => {
              if (value == null || value.value == null) {
                return "";
              }
              return value.value.toLocaleString();
            },
          });
        }
      });
    }

    return defaultCols;
  }

  function getFinancialsColDefs() {
    let defaultCols = [
      {
        field: "Category",
        minWidth: 200,
        headerName: projectDetailsCategoryHeader,
        suppressMovable: true,
        pinned: "left",
        sortable: false,
      },
      {
        field: "Unit",
        width: 100,
        headerName: projectDetailsUnitHeader,
        suppressMovable: true,
        sortable: false,
      },
    ];

    if (financialData.length) {
      const firstRow = financialData[0];
      Object.keys(firstRow).forEach((key) => {
        if (key !== "Category" && key !== "Unit") {
          defaultCols.push({
            field: key,
            minWidth: 200,
            headerName: key,
            suppressMovable: true,
            sortable: false,
            valueFormatter: (value) => {
              if (value == null || value.value == null) {
                return "";
              }
              return value.value.toLocaleString();
            },
          });
        }
      });
    }

    return defaultCols;
  }

  function getBusinessRulesColDefs() {
    switch (selectedProject.projectTypeID) {
      case 1: {
        return getBusinessRulesColDefsForReduce();
      }
      case 2: {
        return getBusinessRulesColDefsForReplace();
      }
      case 3: {
        return getBusinessRulesColDefsForCompensate();
      }
    }
    return;
  }

  function getBusinessRulesColDefsForReduce() {
    return [
      {
        field: "rule_name",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesRuleName,
        headerTooltip: projectDetailsBusinessRulesRuleName,
        suppressMovable: true,
        pinned: "left",
        sortable: false,
      },
      {
        field: "changeType",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesChangeType,
        headerTooltip: projectDetailsBusinessRulesChangeType,
        suppressMovable: true,
        pinned: "left",
        sortable: false,
      },
      {
        field: "data_type",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesDataType,
        headerTooltip: projectDetailsBusinessRulesDataType,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "cumulative_flag",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesCumulative,
        headerTooltip: projectDetailsBusinessRulesCumulative,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "calculation_type",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesUnitOp,
        headerTooltip: projectDetailsBusinessRulesUnitOp,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "datatype_unit",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesUnit,
        headerTooltip: projectDetailsBusinessRulesUnit,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "abatement_value",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesValue,
        headerTooltip: projectDetailsBusinessRulesValue,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "start_year",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesStartYear,
        headerTooltip: projectDetailsBusinessRulesStartYear,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "end_year",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesEndYear,
        headerTooltip: projectDetailsBusinessRulesEndYear,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "scope",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesScope,
        headerTooltip: projectDetailsBusinessRulesScope,
        suppressMovable: true,
        sortable: false,
        cellRenderer: CustomChipComponent,
        cellRendererParams: () => {
          return {
            spec: "scope",
          };
        },
      },
      {
        field: "category_level1",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesSubcat1,
        headerTooltip: projectDetailsBusinessRulesSubcat1,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "category_level2",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesSubcat2,
        headerTooltip: projectDetailsBusinessRulesSubcat2,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "category_level3",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesSubcat3,
        headerTooltip: projectDetailsBusinessRulesSubcat3,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "region_level1",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesReg1,
        headerTooltip: projectDetailsBusinessRulesReg1,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "region_level2",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesReg2,
        headerTooltip: projectDetailsBusinessRulesReg2,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "region_level3",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesReg3,
        headerTooltip: projectDetailsBusinessRulesReg3,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "entity_level1",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesEnt1,
        headerTooltip: projectDetailsBusinessRulesEnt1,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "entity_level2",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesEnt2,
        headerTooltip: projectDetailsBusinessRulesEnt2,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "entity_level3",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesEnt3,
        headerTooltip: projectDetailsBusinessRulesEnt3,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "entity_level4",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesEnt4,
        headerTooltip: projectDetailsBusinessRulesEnt4,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "custom_specification1",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesCust1,
        headerTooltip: projectDetailsBusinessRulesCust1,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "custom_specification2",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesCust2,
        headerTooltip: projectDetailsBusinessRulesCust2,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "custom_specification3",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesCust3,
        headerTooltip: projectDetailsBusinessRulesCust3,
        suppressMovable: true,
        sortable: false,
      },
    ];
  }

  function getBusinessRulesColDefsForReplace() {
    return [
      {
        field: "rule_name",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesRuleName,
        suppressMovable: true,
        pinned: "left",
        sortable: false,
      },
      {
        field: "changeType",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesChangeType,
        suppressMovable: true,
        pinned: "left",
        sortable: false,
      },
      {
        field: "data_type",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesDataType,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "targetHierarchy",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesHierarchy,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "targetHierarchy",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesName,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "calculation_type",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesUnitOp,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "cumulative_flag",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesCumulative,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "datatype_unit",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesUnit,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "abatement_value",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesValue,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "transferAD",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesTransferAD,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "transferAD",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesADValue,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "transferAD",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesADUnit,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "transferAD",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesEfValue,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "start_year",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesStartYear,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "end_year",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesEndYear,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "scope",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesScope,
        suppressMovable: true,
        sortable: false,
        cellRenderer: CustomChipComponent,
        cellRendererParams: () => {
          return {
            spec: "scope",
          };
        },
      },
      {
        field: "category_level1",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesSubcat1,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "category_level2",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesSubcat2,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "category_level3",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesSubcat3,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "region_level1",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesReg1,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "region_level2",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesReg2,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "region_level3",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesReg3,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "entity_level1",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesEnt1,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "entity_level2",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesEnt2,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "entity_level3",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesEnt3,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "entity_level4",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesEnt4,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "custom_specification1",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesCust1,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "custom_specification2",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesCust2,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "custom_specification3",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesCust3,
        suppressMovable: true,
        sortable: false,
      },
    ];
  }

  function getBusinessRulesColDefsForCompensate() {
    return [
      {
        field: "rule_name",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesRuleName,
        suppressMovable: true,
        pinned: "left",
        sortable: false,
      },
      {
        field: "changeType",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesChangeType,
        suppressMovable: true,
        pinned: "left",
        sortable: false,
      },
      {
        field: "data_type",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesDataType,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "cumulative_flag",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesCumulative,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "calculation_type",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesUnitOp,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "datatype_unit",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesUnit,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "abatement_value",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesValue,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "start_year",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesStartYear,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "end_year",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesEndYear,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "scope",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesScope,
        suppressMovable: true,
        sortable: false,
        cellRenderer: CustomChipComponent,
        cellRendererParams: () => {
          return {
            spec: "scope",
          };
        },
      },
      {
        field: "category_level1",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesSubcat1,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "category_level2",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesSubcat2,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "category_level3",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesSubcat3,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "region_level1",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesReg1,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "region_level2",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesReg2,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "region_level3",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesReg3,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "entity_level1",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesEnt1,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "entity_level2",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesEnt2,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "entity_level3",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesEnt3,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "entity_level4",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesEnt4,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "custom_specification1",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesCust1,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "custom_specification2",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesCust2,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "custom_specification3",
        minWidth: 200,
        headerName: projectDetailsBusinessRulesCust3,
        suppressMovable: true,
        sortable: false,
      },
    ];
  }

  return (
    <div className="project-details">
      <div className="project-details-header">
        <Button variant="secondary" onClick={onPreviousPageClick}>
          <ArrowLeftIcon />
          {BackBtn}
        </Button>
      </div>
      <div className="project-details-content">
        <div className="project-details-name">
          <div className="project-details-name-n">
            <span>{selectedProject.projectName}</span>
            {projectScopes.length > 1 ? (
              <MotifTooltip
                variant="alt"
                hideCloseButton
                trigger={
                  <MotifChipGroup>
                    <MotifChip title={projectScopes[0]}>
                      {projectScopes[0]}
                    </MotifChip>
                    <MotifChip title={projectScopes.slice(1).join(", ")}>
                      {"+" + (projectScopes.length - 1)}
                    </MotifChip>
                  </MotifChipGroup>
                }
              >
                {projectScopes.join(",")}
              </MotifTooltip>
            ) : (
              projectScopes.map((name, index) => {
                return (
                  <MotifChip key={index} label={name}>
                    {`${name}`}
                  </MotifChip>
                );
              })
            )}
          </div>
          <div className="project-details-name-type">
            <span>{projectDetailsType}</span>
            <MotifChip className="project-details-type">
              {projectTypes[selectedProject.projectTypeID]}
            </MotifChip>
            <span>{projectDetailsTags}</span>
            {selectedProject?.tagNames?.map((tag, index) => {
              return (
                <MotifChip
                  key={index}
                  label={tag}
                  className="project-details-tags"
                >
                  {tag}
                </MotifChip>
              );
            })}
          </div>
        </div>

        <div className="project-details-desc">
          <span>{selectedProject.projectDescription}</span>
        </div>
        <div className="project-details-bcs">
          <span>{projectDetailsBusinessRules}</span>
        </div>
        <div className="project-details-table">
          <Table
            colDefs={getBusinessRulesColDefs()}
            rowData={businessRules}
            draggable={false}
            firstColumnBorderRight
            loading={businessRulesLoading}
            rowSelect={true}
          />
        </div>
        <div className="project-details-bcs">
          <span>{"Financial parameters"}</span>
        </div>
        <div className="project-details-table">
          <Table
            colDefs={gmpData?.tableHeaderData}
            rowData={gmpData?.tableData ?? []}
            draggable={false}
            firstColumnBorderRight
            loading={gmpDataLoading}
            suppressMovableColumns={true}
            rowSelect={true}
          />
        </div>
        <div className="project-details-bcs">
          <span>{projectDetailsFinancialsHeader}</span>
        </div>
        <div className="project-details-table">
          <Table
            colDefs={getFinancialsColDefs()}
            rowData={financialData}
            pagesize={2}
            draggable={false}
            firstColumnBorderRight
            loading={abatementFinancialDataLoading}
            suppressMovableColumns={true}
          />
        </div>
        <div className="project-details-bcs">
          <span>{projectResultsBusinessCaseSummaryHeader}</span>
        </div>
        <div className="project-details-bcs-tiles">
          <MotifCard orientation="horizontal">
            <MotifCardHeader variant="alt">
              <span className="project-details-bcs-tiles-header">
                {projectDetailsItemSImplePayback}
              </span>
            </MotifCardHeader>
            <MotifCardBody>
              <LoadingIndicator
                show={summaryLoading}
                transparent
                fullscreen={false}
              >
                <span>{summary?.simplePayBackPeriod}</span>
              </LoadingIndicator>
            </MotifCardBody>
          </MotifCard>
          <MotifCard orientation="horizontal">
            <MotifCardHeader variant="alt">
              <span className="project-details-bcs-tiles-header">
                {projectDetailsItemDPP}
              </span>
            </MotifCardHeader>
            <MotifCardBody>
              <LoadingIndicator
                show={summaryLoading}
                transparent
                fullscreen={false}
              >
                <span>{summary?.discountedPayBackPeriod}</span>
              </LoadingIndicator>
            </MotifCardBody>
          </MotifCard>
          <MotifCard orientation="horizontal">
            <MotifCardHeader variant="alt">
              <span className="project-details-bcs-tiles-header">
                {projectDetailsItemNetPresent}
              </span>
            </MotifCardHeader>
            <MotifCardBody>
              <LoadingIndicator
                show={summaryLoading}
                transparent
                fullscreen={false}
              >
                <span>
                  {summary?.netPresentValue
                    ? numberFormatter.format(summary?.netPresentValue)
                    : ""}
                </span>
              </LoadingIndicator>
            </MotifCardBody>
          </MotifCard>
          <MotifCard orientation="horizontal">
            <MotifCardHeader variant="alt">
              <span className="project-details-bcs-tiles-header">
                {projectDetailsItemTechAbatement}
              </span>
            </MotifCardHeader>
            <MotifCardBody>
              <LoadingIndicator
                show={summaryLoading}
                transparent
                fullscreen={false}
              >
                <span>{`${summary?.technicalAbatement ?? "-"}`}</span>
              </LoadingIndicator>
            </MotifCardBody>
          </MotifCard>
          <MotifCard orientation="horizontal">
            <MotifCardHeader variant="alt">
              <span className="project-details-bcs-tiles-header">
                {projectDetailsItemMargCost}
              </span>
            </MotifCardHeader>
            <MotifCardBody>
              <LoadingIndicator
                show={summaryLoading}
                transparent
                fullscreen={false}
              >
                <span>{`${
                  numberFormatter.format(summary?.marginalAbatementCost) ?? ""
                }`}</span>
              </LoadingIndicator>
            </MotifCardBody>
          </MotifCard>
          <MotifCard orientation="horizontal">
            <MotifCardHeader variant="alt">
              <span className="project-details-bcs-tiles-header">
                {projectDetailsItemRateOfReturn}
              </span>
            </MotifCardHeader>
            <MotifCardBody>
              <LoadingIndicator
                show={summaryLoading}
                transparent
                fullscreen={false}
              >
                <span>{`${summary?.internalRateOfReturn ?? "-"}`}</span>
              </LoadingIndicator>
            </MotifCardBody>
          </MotifCard>
        </div>
        <div className="project-details-bcs">
          <span>{projectDetailsAbatementCashFlowCalcHeader}</span>
        </div>
        <div className="project-details-table">
          <Table
            colDefs={getAbatementColDefs()}
            rowData={abatementData}
            draggable={false}
            firstColumnBorderRight
            loading={abatementFinancialDataLoading}
          />
        </div>
      </div>
    </div>
  );
}
