import { BlobServiceClient } from "@azure/storage-blob";
import { ENGAGEMENT_URL } from "./endpoints/engagement";
import { post } from "./base.service";

async function getSaSToken({ request }) {
  return await post(
    // `${ENGAGEMENT_URL.GetSaSToken}?fileName=${request.fileName}&modulename=${request.modulename}`
    `/WebApi/Invoke`, {
      ActionVerb: "POST",
      ControllerName: "Engagement",
      ActionName: ENGAGEMENT_URL.GetSaSToken,
      QueryString: 'fileName=' + request.fileName + `&` + 'modulename=' + request.modulename,
      Data: ""
    }
  );
}

async function uploadFileToBlob({
  account = process.env.REACT_APP_AZURE_STORAGE_ACCOUNT_NAME,
  containerName = "decarb-dataingestion-raw",
  moduleName = "",
  fileName = "",
  file,
}) {
  //const sasToken = process.env.REACT_APP_AZURE_STORAGE_SAS_TOKEN;
  const sasToken = await getSaSToken({
      request: { fileName: fileName, modulename: moduleName },
  });
  const blobServiceClient = new BlobServiceClient(
    `https://${account}.blob.core.windows.net?${sasToken}`
  );

  const containerClient = blobServiceClient.getContainerClient(containerName);

  const blobName = `${moduleName}/${fileName}`;
  let blockBlobClient = containerClient.getBlockBlobClient(blobName);

  const options = {
    blobHTTPHeaders: {
      blobContentType: file.type,
    },
  };

  await blockBlobClient.uploadBrowserData(file, options);
}

async function downloadFileFromBlob({
  account = process.env.REACT_APP_AZURE_STORAGE_ACCOUNT_NAME,
  containerName = "decarb-dataingestion-raw",
  moduleName = "",
  fileName = "",
}) {
  //const sasToken = process.env.REACT_APP_AZURE_STORAGE_SAS_TOKEN;
  const sasToken = await getSaSToken({
    request: { fileName: fileName, modulename: moduleName },
  });
  const blobServiceClient = new BlobServiceClient(
    `https://${account}.blob.core.windows.net?${sasToken}`
  );

  const containerClient = blobServiceClient.getContainerClient(containerName);

  const blobName = `${moduleName}/${fileName}`;
  let blockBlobClient = containerClient.getBlockBlobClient(blobName);

  const downloadBlockBlobResponse = await blockBlobClient.download();
  const downloaded = await downloadBlockBlobResponse.blobBody;

  return downloaded;
}

export { uploadFileToBlob, downloadFileFromBlob };
