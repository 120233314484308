export const FORECAST_URL = {
  GetForecastRecords: `GetAllForecastSpecs`,
  SaveForecastSpecification: `SaveSpecification`,
  GetTargetRecords: `GetAllTargetSpecs`,
  SaveTargetSpecification: `SaveTargetSpecifications`,
  UpdateTargetSpecification: `UpdateTargetSpecifications`,
  UpdateForecastSpecification: `UpdateSpecification`,
  GetTargetSpecificationByBatchGroupId: `GetTargetSpecificationByID`,
  GetForecastSpecificationByBatchGroupId: `GetForecastSpecificationByID`,
  UpdateBatchIdForTargetSpecifcationAndCallSP: `UpdateBatchIdForTargetSpecifcationAndCallSP`,
  UpdateBatchIdForForecastSpecifcationAndCallSP: `UpdateBatchIdForForecastSpecifcationAndCallSP`,
  DeleteForecast: `DeleteForecastSpecification`,
  DeleteTarget: `DeleteTargetSpecification`,
  GetForecastSpecificationProcessingStatus: `GetForecastSpecificationProcessingStatus`,
  GetTargetSpecificationProcessingStatus: `GetTargetSpecificationProcessingStatus`,
  AllSaveForecastspecification: `SaveForecastSpecification`,
  AllSaveTargetspecification: `SaveTargetSpecification`,
  AllUpdateForecast: `UpdateForecastSpecification`,
  AllUpdateTarget: `UpdateTargetSpecification`,
  ApplyForecast: `ApplyForecast`,
  ApplyTarget: `ApplyTarget`,
  SetShowForecast: `SetShowForecast`,
  SetShowTarget: `SetShowTarget`,
  checkName: `CheckIfNameExist`,
  UpdateForeCastEndYearData: `UpdateForeCastEndYearData`,
  SaveManualIntensityTarget: "SaveManualIntensityTargetSpecifications",
  UpdateManualIntensityTarget: "UpdateManualIntensityTargetSpecifications",
  GetM2TargetDownloadData: "GetM2TargetDownloadData",
  GetM2ForeCastDownloadData: "GetM2ForeCastDownloadData",
};
