import { useNavigate } from "react-router-dom";
import { routes } from "../../routes/routes.constatns";
import {
  Button,
  ForecastChart,
  ForecastModal,
  Input,
  LoadingIndicator,
  Select,
} from "..";
import "./Forecast.scss";
import {
  ForecastIcon,
  GraphIcon,
  TargetIcon,
  SaveIcon,
  EditIcon,
  DownloadIcon,
  DeleteLogo,
  EmissionIcon,
} from "../../icons";
import { ForcastResetModal } from "./components/ForecastResetModal/ForcastResetModal";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  getEngagementById,
  setEngagementForecastEndYear,
  setIsForecastEndYearDisabled,
  setIsSaveAndNextDisabled,
  getResetM2Data,
} from "../../store/slices/engagementSlice";
import {
  forecastBodyChartTitle,
  PoweBiBtn,
  forecastHeaderDesc,
  forecastHeaderMain,
  forecastBodyCalculateEmissionsSummaryTitle,
  forecastBodyCalculateEmissionsSummaryTargetRequired,
  forecastBodyCalculateEmissionsSummaryForecastRequired,
  forecastBodyCalculateEmissionsSummaryRelativeToTargetYear,
  forecastBodyCalculateEmissionsSummaryEmissionReductioonGap,
  forecastBodyCalculateEmissionsSummaryRelativeToBaseYear,
  forecastBodyCalculateEmissionsSummaryEmissionReducationTarget,
  forecastBodyCalculateEmissionsSummaryEmissionCoverage,
  forecastBodyCalculateEmissionsSummaryCoveredEmission,
  forecastBodyCalculateEmissionsSummaryChangeOfEmission,
  AddForecastBtn,
  AddTargetBtn,
  ResetM2Data,
  foreEndYear,
  forecastBodyChartScope1And2,
  forecastBodyChartScope3,
  ExportBtn,
  forecastendYearTooltip,
  addforecastTooltip,
  addtargetTooltip,
} from "../../util/constants";
import {
  setScope,
  setIsSkipModalOpen,
  dropForecastChartState,
  setIsSaveFEYInProgress,
  triggerForecastRecalculate,
  triggerTargetRecalculate,
  dropEmissionsChartState,
} from "../../store/slices/forecastSlice";
import { MotifCard, MotifCardBody, MotifCardHeader } from "@ey-xd/motif-react";
import { AddForecastModal } from "./components/AddForecastModal/AddForecastModal";
import { updateForecastEndYear } from "../../store/services/engagement.service";
import { ChangeForecastEndYearModal } from "./components/ChangeForecastEndYearModal/ChangeForecastEndYearModal";
import {
  getM2ForeCastDownloadData,
  getM2TargetDownloadData,
  updateForeCastEndYearData,
} from "../../store/services/forecast.service";
import { createExcelFile } from "../../util/excelUtil";
import { saveAs } from "file-saver";
import { formarDateTime, numberFormatter } from "../../util/dateUtil";
import { getLockingData } from "../../store/slices/projectSlice";
import { LockerModal } from "../LockerModal/lockerModal";
import { LoadingIndicatorEmbed } from "../LoadingIndicator/LoadingIndicatorEmbed";
import { Tooltip } from "../CommonUIComponents/Tooltip/Tooltip";

export function Forecast() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const calculatedEmissionsLoading = useSelector(
    (state) => state.forecast.calculatedEmissionsLoading
  );
  const isSkipModalOpen = useSelector(
    (state) => state.forecast.isSkipModalOpen
  );
  const [isModalVisible, setForecastModalVisible] = useState(false);
  const [isResetModalVisible, setResetForcastModalVisible] = useState(false);
  const [isChangeFEYModalVisible, setIsChangeFEYModalVisible] = useState(false);
  const engagement = useSelector((state) => state.engagement.engagement);
  const scope = useSelector((state) => state.forecast.scope);
  const calculatedEmissions = useSelector(
    (state) => state.forecast.calculatedEmissions
  );
  const forecastsCount = useSelector((state) => state.forecast.forecastsCount);
  const targetsCount = useSelector((state) => state.forecast.targetsCount);
  const forecastInprogressCount = useSelector(
    (state) => state.engagement.engagement.forecastDraftCount
  );
  const targetInprogressCount = useSelector(
    (state) => state.engagement.engagement.targetDraftCount
  );
  const emissionsChartDataLoading = useSelector(
    (state) => state.forecast.emissionsChartDataLoading
  );
  const targetsChartDataLoading = useSelector(
    (state) => state.forecast.targetsChartDataLoading
  );
  const emissionsNoTargetChartData = useSelector(
    (state) => state.forecast.emissionsNoTargetChartData
  );
  const emissionsTargetChartData = useSelector(
    (state) => state.forecast.emissionsTargetChartData
  );
  const targetsChartData = useSelector(
    (state) => state.forecast.targetsChartData
  );
  const resetM2DataLoading = useSelector(
    (state) => state.engagement.resetM2DataLoading
  );
  const [forecastYear, setForecastYear] = useState(0);
  const [forecastErrorMessage, setForecastErrorMessage] = useState("");
  const isForecastEndYearDisabled = useSelector(
    (state) => state.engagement.isForecastEndYearDisabled
  );
  const [isExportM2DataInProgress, setIsExportM2DataInProgress] =
    useState(false);
  const [lockedData, setLock] = useState();
  const [lockedBy, setLockedby] = useState();
  const [isLockerModalOpen, setLockerModalOpen] = useState(false);

  function onModalClose() {
    dispatch(setIsSkipModalOpen({ isOpen: false }));
  }

  async function onModalConfirm() {
    dispatch(setIsSkipModalOpen({ isOpen: false }));
  }

  async function onResetForcastModalConfirm() {
    setResetForcastModalVisible(false);
    dispatch(getResetM2Data({ engagementId: engagement.id }));
    dispatch(dropForecastChartState());
  }

  useEffect(() => {
    dispatch(getEngagementById({ id: engagement.id }));

    if (resetM2DataLoading) {
      dispatch(setIsSaveAndNextDisabled({ isDisabled: true }));
    } else {
      dispatch(setIsSaveAndNextDisabled({ isDisabled: false }));
    }
  }, [resetM2DataLoading]);

  useEffect(() => {
    setForecastModalVisible(false);
    setResetForcastModalVisible(false);
    dispatch(setScope({ scope: 2 }));
    dispatch(setIsSaveAndNextDisabled({ isDisabled: false }));
  }, [dispatch, engagement?.id]);

  function onScopeChange(event) {
    dispatch(setScope({ scope: event }));
  }
  const onClose = () => {
    setForecastModalVisible(false);
  };

  const onResetForcastModalClose = () => {
    setResetForcastModalVisible(false);
  };

  useEffect(() => {
    const request = {
      LockedBy: user.username,
      ModuleName: "Engagement",
      EngagementID: engagement.id,
    };

    dispatch(getLockingData(request))
      .then((action) => {
        if (action) {
          const lockedData = action.payload.data.lockStatus;
          const lockedBy = action.payload.data.lockedBy;
          setLockedby(lockedBy);
          setLock(lockedData);
          if (lockedData === 2) {
            setLockerModalOpen(true);
          }
        } else {
          console.error("Failed to fetch locking data:", action.error);
        }
      })
      .catch((error) => {
        console.error("Error fetching locking data:", error);
      });
  }, [dispatch, lockedData]);

  const onForecastYearChange = async (event) => {
    let year = event.target.value;

    setForecastYear(year);
    isForecastYearValid(year);
  };

  function isForecastYearValid(year) {
    if (year) {
      if (parseFloat(year) <= engagement.mostRecentYear) {
        setForecastErrorMessage(
          "Forecast end year should be greater than most recent year."
        );
        return false;
      }
      if (parseFloat(year) > engagement.mostRecentYear + 30) {
        setForecastErrorMessage(
          "Forecast end year should be within 30 years from the most recent year."
        );
        return false;
      } else {
        setForecastErrorMessage("");

        return true;
      }
    } else {
      setForecastErrorMessage("The forecast year is required.");
      return false;
    }
  }

  async function saveFEY() {
    if (isForecastYearValid(forecastYear)) {
      dispatch(setIsForecastEndYearDisabled({ isDisabled: true }));
      dispatch(setIsSaveFEYInProgress({ isSaveInProgress: true }));
      try {
        const isFirstTime = engagement.foreCastEndYear === null;

        const request = {
          engagementId: engagement.id,
          forecastEndYear: forecastYear,
        };
        await updateForecastEndYear({ request });

        dispatch(
          setEngagementForecastEndYear({
            endYear: parseFloat(forecastYear),
          })
        );

        if (!isFirstTime) {
          await updateForeCastEndYearData({
            request: { engagementCode: engagement.engagementCode },
          });
        }

        dispatch(dropEmissionsChartState());

        if (engagement.show_Forecast === 1) {
          dispatch(triggerForecastRecalculate());
        }

        if (engagement.show_target === 1) {
          dispatch(triggerTargetRecalculate());
        }

        dispatch(setIsSaveFEYInProgress({ isSaveInProgress: false }));
      } catch (e) {
        console.error(e);
        dispatch(setIsSaveFEYInProgress({ isSaveInProgress: false }));
      }
    }
  }

  function onChangeFEYModalConfirm() {
    dispatch(setIsForecastEndYearDisabled({ isDisabled: false }));
    setForecastYear(engagement.foreCastEndYear);
    setIsChangeFEYModalVisible(false);
  }

  /**
   * @method onExportDataClick
   * This function will export the forecast and target data to an excel file.
   */
  async function onExportDataClick() {
    setIsExportM2DataInProgress(true);

    const request = {
      engagementCode: engagement.engagementCode,
      startYear: parseInt(engagement.mostRecentYear),
    };

    try {
      const forecastDownloadedData = await getM2ForeCastDownloadData({
        request,
      });

      let newYearKeys = [];
      let newStartYear = engagement.mostRecentYear - 10;

      for (let i = 0; i < 40; i++) {
        newYearKeys.push((newStartYear + 1).toString());
        newStartYear++;
      }

      const forecastDataForExport = replaceHeaders(
        forecastDownloadedData.data,
        newYearKeys
      );

      let targetDataForExport = [];

      if (targetsCount > 0) {
        const targetDownloadedData = await getM2TargetDownloadData({ request });
        targetDataForExport = replaceHeaders(
          targetDownloadedData.data,
          newYearKeys
        );
      }

      const m2DataForExportBlob = await createExcelFile([
        {
          data: forecastDataForExport,
          name: "Forecast Data",
        },
        {
          data: targetDataForExport,
          name: "Target Data",
        },
      ]);

      const exportM2DataFileName = `EYDecarb_${
        engagement.engagementCode
      }_Module2_ExportedM2Data_${formarDateTime(
        new Date(),
        "ddMMyyyy HH:mm:ss"
      )}.xlsx`;
      saveAs(m2DataForExportBlob, exportM2DataFileName);

      setIsExportM2DataInProgress(false);
    } catch (e) {
      console.error(e);
      setIsExportM2DataInProgress(false);
    }
  }

  /**
   * @method replaceHeaders
   * This function will replace the year keys of the downloaded data with the new keys.
   */
  function replaceHeaders(downloadedData, newYearKeys) {
    let dataForExport = [];

    for (let i = 0; i < downloadedData.length; i++) {
      let yearIndex = -1;

      const yearKeys = Object.keys(downloadedData[i]).map((x) => {
        if (x.includes("year")) {
          yearIndex++;
          return {
            [" " + newYearKeys[yearIndex]]: downloadedData[i][x],
          };
        } else {
          return { [" " + x]: downloadedData[i][x] };
        }
      });

      dataForExport.push(Object.assign(...yearKeys));
    }

    return dataForExport;
  }

  return (
    <div className="forecast">
      <div className="forecast-header">
        <div className="forecast-header-a">
          <div className="forecast-header-aa">
            <span className="forecast-header-main">{forecastHeaderMain}</span>
            <span className="forecast-header-desc">{forecastHeaderDesc}</span>
          </div>
          <div className="forecast-body-results-analytics">
            <Button
              onClick={() => {
                setForecastModalVisible(true);
              }}
              variant="primary-alt"
              className={
                forecastInprogressCount > 0 ? "button-color-yellow" : ""
              }
              disabled={!engagement.foreCastEndYear}
              tooltip={addforecastTooltip}
            >
              <ForecastIcon />
              {AddForecastBtn}
              {!forecastsCount ? "" : <p>{forecastsCount}</p>}
            </Button>

            <Button
              onClick={() => {
                navigate(`${routes.addTarget}`, {
                  state: {
                    engagementId: engagement.id,
                  },
                  gestureEnabled: false,
                });
              }}
              variant="primary-alt"
              className={targetInprogressCount > 0 ? "button-color-yellow" : ""}
              disabled={
                engagement.show_Forecast === 0 || !engagement.foreCastEndYear
              }
              tooltip={addtargetTooltip}
            >
              <TargetIcon />
              {AddTargetBtn}
              {!targetsCount ? "" : <p>{targetsCount}</p>}
            </Button>
            <LoadingIndicatorEmbed
              show={isExportM2DataInProgress}
              fullscreen={false}
              transparent
            >
              <Button
                onClick={() => {
                  onExportDataClick();
                }}
                disabled={
                  !engagement.foreCastEndYear ||
                  engagement.show_Forecast === 0 ||
                  isExportM2DataInProgress ||
                  (lockedData === 1 && lockedBy !== user.username)
                }
                variant="secondary"
              >
                <DownloadIcon />
                {ExportBtn}
              </Button>
            </LoadingIndicatorEmbed>
            <Button
              onClick={() => {
                setResetForcastModalVisible(true);
              }}
              disabled={
                (emissionsNoTargetChartData.length === 0 &&
                  emissionsTargetChartData.length === 0 &&
                  targetsChartData.length === 0) ||
                !engagement.foreCastEndYear ||
                (lockedData === 1 && lockedBy !== user.username)
              }
              variant="secondary"
            >
              <DeleteLogo />
              {ResetM2Data}
            </Button>
          </div>
        </div>
        <div className="forecast-body-results">
          <div className="forecast-body-upload-year">
            {isForecastEndYearDisabled ? (
              <>
                <span className="forecast-body-upload-year-edit-title decarb-input-error-label">
                  {foreEndYear}
                  <Tooltip
                    trigger={<EmissionIcon />}
                    variant="ghost"
                    tooltipText={forecastendYearTooltip}
                  ></Tooltip>
                  <span className="required-star">*</span>
                </span>
                <span>{engagement.foreCastEndYear}</span>
                <Button
                  onClick={() => {
                    setIsChangeFEYModalVisible(true);
                  }}
                  variant="primary-alt"
                  className={"forecast-body-upload-year-edit-button"}
                  disabled={lockedData === 1 && lockedBy !== user.username}
                >
                  <EditIcon />
                </Button>
              </>
            ) : (
              <>
                <span className="forecast-body-upload-year-save-title decarb-input-error-label">
                  {foreEndYear}
                  <Tooltip
                    trigger={<EmissionIcon />}
                    variant="ghost"
                    tooltipText={forecastendYearTooltip}
                  ></Tooltip>
                  <span className="required-star">*</span>
                </span>
                <Input
                  id="base-year-scope-3"
                  label="Input"
                  onChange={onForecastYearChange}
                  value={forecastYear}
                  required
                  type="number"
                  maxlength={4}
                  isError={forecastErrorMessage}
                  errorMessage={forecastErrorMessage}
                  disableErrorPlaceholder={false}
                  disabled={isForecastEndYearDisabled}
                  className={"forecast-body-upload-fey-input"}
                />
                <Button
                  onClick={() => {
                    saveFEY();
                  }}
                  disabled={forecastErrorMessage}
                  variant="primary-alt"
                  className={"forecast-body-upload-year-save-button"}
                >
                  <SaveIcon />
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="forecast-body-chart-section">
        <div className="forecast-body-chart-section-a">
          <span className="forecast-body-chart-section-title">
            {forecastBodyChartTitle.replace(
              "$1",
              scope === 2
                ? forecastBodyChartScope1And2
                : forecastBodyChartScope3
            )}
          </span>
          <div className="forecast-body-results-powewrbi">
            <Select
              disabled={
                emissionsChartDataLoading ||
                targetsChartDataLoading ||
                resetM2DataLoading ||
                !engagement.foreCastEndYear
              }
              value={scope}
              onChange={onScopeChange}
              options={[
                {
                  name: forecastBodyChartScope1And2,
                  id: 2,
                },
                {
                  name: forecastBodyChartScope3,
                  id: 3,
                },
              ]}
            />
            <Button
              onClick={() => {
                navigate(`/${routes.powerBI}`, {
                  state: {
                    reportId: process.env.REACT_APP_POWER_BI_REPORT_ID_M2,
                  },
                  gestureEnabled: false,
                });
              }}
              variant="secondary"
              disabled={
                emissionsChartDataLoading ||
                targetsChartDataLoading ||
                resetM2DataLoading ||
                !engagement.foreCastEndYear
              }
            >
              <GraphIcon />
              {PoweBiBtn}
            </Button>
          </div>
        </div>
        <div className="forecast-body-chart-section-chart">
          <ForecastChart />
        </div>
        <div className="forecast-body-chart-section-a">
          <span className="forecast-body-chart-section-title">
            {forecastBodyCalculateEmissionsSummaryTitle}
          </span>
        </div>
        <div className="forecast-body-chart-section-summary">
          <MotifCard
            className={
              calculatedEmissionsLoading ? "forecast-summary-loading" : ""
            }
          >
            <MotifCardHeader>
              <span className="motif-h6">
                {forecastBodyCalculateEmissionsSummaryChangeOfEmission}
              </span>
              <span>
                {forecastBodyCalculateEmissionsSummaryRelativeToBaseYear}
              </span>
            </MotifCardHeader>
            <LoadingIndicator
              show={calculatedEmissionsLoading}
              fullscreen={false}
              transparent
            >
              <MotifCardBody>
                <span className="summary-percent">
                  {calculatedEmissions?.percentageChangeofEmission !==
                    undefined &&
                  calculatedEmissions?.percentageChangeofEmission !== "-"
                    ? `${calculatedEmissions?.percentageChangeofEmission}%`
                    : "-"}
                </span>
                <span>
                  {calculatedEmissions?.changeOfEmission !== undefined
                    ? `${numberFormatter.format(
                        calculatedEmissions?.changeOfEmission
                      )} tCO\u{2082}e`
                    : forecastBodyCalculateEmissionsSummaryForecastRequired}
                </span>
              </MotifCardBody>
            </LoadingIndicator>
          </MotifCard>

          <MotifCard
            className={
              calculatedEmissionsLoading ? "forecast-summary-loading" : ""
            }
          >
            <MotifCardHeader variant="alt">
              <span className="motif-h6">
                {forecastBodyCalculateEmissionsSummaryCoveredEmission}
              </span>
              <span>
                {forecastBodyCalculateEmissionsSummaryEmissionCoverage}
              </span>
            </MotifCardHeader>
            <LoadingIndicator
              show={calculatedEmissionsLoading}
              fullscreen={false}
              transparent
            >
              <MotifCardBody>
                <span className="summary-percent">
                  {calculatedEmissions?.percentageCoveredEmissions !==
                    undefined &&
                  calculatedEmissions?.percentageCoveredEmissions !== "-"
                    ? `${calculatedEmissions?.percentageCoveredEmissions}%`
                    : "-"}
                </span>
                <span>
                  {calculatedEmissions?.coveredEmissions !== undefined
                    ? `${numberFormatter.format(
                        calculatedEmissions?.coveredEmissions
                      )} tCO\u{2082}e`
                    : forecastBodyCalculateEmissionsSummaryForecastRequired}
                </span>
              </MotifCardBody>
            </LoadingIndicator>
          </MotifCard>
          <MotifCard
            className={
              calculatedEmissionsLoading ? "forecast-summary-loading" : ""
            }
          >
            <MotifCardHeader variant="alt">
              <span className="motif-h6">
                {forecastBodyCalculateEmissionsSummaryEmissionReducationTarget}
              </span>
              <span>
                {forecastBodyCalculateEmissionsSummaryRelativeToBaseYear}
              </span>
            </MotifCardHeader>
            <LoadingIndicator
              show={calculatedEmissionsLoading}
              fullscreen={false}
              transparent
            >
              <MotifCardBody>
                <span className="summary-percent">
                  {calculatedEmissions?.percentageEmissionReductionTarget !==
                    undefined &&
                  calculatedEmissions?.percentageEmissionReductionTarget !== "-"
                    ? `${calculatedEmissions?.percentageEmissionReductionTarget}%`
                    : "-"}
                </span>
                <span>
                  {calculatedEmissions?.emissionReductionTarget !== undefined
                    ? `${numberFormatter.format(
                        calculatedEmissions?.emissionReductionTarget
                      )} tCO\u{2082}e`
                    : forecastBodyCalculateEmissionsSummaryTargetRequired}
                </span>
              </MotifCardBody>
            </LoadingIndicator>
          </MotifCard>
          <MotifCard
            className={
              calculatedEmissionsLoading ? "forecast-summary-loading" : ""
            }
          >
            <MotifCardHeader variant="alt">
              <span className="motif-h6">
                {forecastBodyCalculateEmissionsSummaryEmissionReductioonGap}
              </span>
              <span>
                {forecastBodyCalculateEmissionsSummaryRelativeToTargetYear}
              </span>
            </MotifCardHeader>
            <LoadingIndicator
              show={calculatedEmissionsLoading}
              fullscreen={false}
              transparent
            >
              <MotifCardBody>
                <span className="summary-percent">
                  {calculatedEmissions?.percentageEmissionReductionGap !==
                    undefined &&
                  calculatedEmissions?.percentageEmissionReductionGap !== "-"
                    ? `${calculatedEmissions?.percentageEmissionReductionGap}%`
                    : "-"}
                </span>
                <span>
                  {calculatedEmissions?.emissionReductionGap !== undefined
                    ? `${numberFormatter.format(
                        calculatedEmissions?.emissionReductionGap
                      )} tCO\u{2082}e`
                    : forecastBodyCalculateEmissionsSummaryTargetRequired}
                </span>
              </MotifCardBody>
            </LoadingIndicator>
          </MotifCard>
        </div>
      </div>
      <ForecastModal
        isModalVisible={isSkipModalOpen}
        onClose={onModalClose}
        onConfirm={onModalConfirm}
      />
      <AddForecastModal
        isForecastModalVisible={isModalVisible}
        onClose={onClose}
        forecastEndYear={engagement.foreCastEndYear}
      />
      <ForcastResetModal
        isModalVisible={isResetModalVisible}
        onClose={onResetForcastModalClose}
        onConfirm={onResetForcastModalConfirm}
      />
      <ChangeForecastEndYearModal
        isModalVisible={isChangeFEYModalVisible}
        onClose={() => {
          setIsChangeFEYModalVisible(false);
        }}
        onConfirm={onChangeFEYModalConfirm}
      />
      {lockedData === 2 && lockedBy === user.username && (
        <LockerModal
          isOpen={isLockerModalOpen}
          onClose={() => setLockerModalOpen(false)}
        />
      )}
    </div>
  );
}
