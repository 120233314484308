export const GMP_URL = {
  GetParametersList: `GetParametersList`,
  GetRulesList: `GetRulesList`,
  SaveDecarbGmpRuleMap: `SaveDecarbGmpRuleMap`,
  GetGMPRulesMapData: `GetGMPRulesMapData`,
  DeleteGmpRule: `DeleteGmpRule`,
  GetGMPDataURL: `GetAllGMPDetails`,
  DeleteGMPDetail: `DeleteGMPParameterDetail`,
  SaveGMPData: `SaveGMPData`,
  UpdateGMPDataManully: `UpdateGMPDataManully`,
  GetParameterMatch: `GetParameterMatch`,
  ApplyGMPRules: `ApplyGMPRules`,
  ExportToExcel: `ExportToExcel`,
};
